div.product-detail-wrapper {
  margin-bottom: 30px;

  div.product-detail {
    @include theme(border, cb-border-default, 1px solid);
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    transition: background-color, box-shadow 0.3s linear;
    padding: 20px;
    border-radius: 5px;

    &.highlight-border {
      @include theme(border, cb-border-default, 1px solid);
      @include theme(border-left, cb-primary-main, 6px solid);
    }

    div.product-detail-header {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        .title {
          @include theme(color, cb-primary-main);
          transition: color 0.3s linear;
        }

        .expand-btn-label {
          text-decoration: underline;
        }
      }

      .product-detail-header-content {
        display: flex;
      }

      div.img {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-right: 10px;
  
        img {
          width: 32px;
          height: 32px;
        }
      }

      .title {
        line-height: 32px;
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        @include theme(color, cb-text-primary);
        transition: color 0.3s linear;
      }

      .expand-btn-wrapper {
        align-self: flex-end;
        line-height: 32px;

        .expand-btn-icons {
          border: 0;
          background: transparent;
          @include theme(color, cb-primary-main);
          height: 32px;
          padding: 0;
          line-height: 32px;
          font-size: 16px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          cursor: pointer;
        }

        .material-symbols-rounded {
          line-height: 32px;
          margin-left: 10px;
          font-variation-settings:
          'FILL' 1,
          'wght' 400,
          'GRAD' 0,
          'opsz' 32
        }
      }
    }

    .desc {
      align-self: flex-start;
      margin-top: 10px;
      @include theme(color, cb-text-primary);

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .product-detail.closed {
    .component-area {
      display: none;
    }

    .expand-icon-open {
      display: inline;
    }
    .expand-icon-close {
      display: none;
    }
  }

  .product-detail.expanded {
    .expand-icon-open {
      display: none;
    }
    .expand-icon-close {
      display: inline;
    }
  }

  .product-detail.expanded, .product-detail.edit {
    .component-area {
      display: block;
    }
  }

  .component-area {
    @include theme(border, cb-border-default, 1px solid);
    border-radius: 5px;
    margin-top: 20px;

    > .text-block {
      padding: 20px;

      .rich_text {
        margin: 0;

        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }	
}

@media (max-width: 1279px) {
  div.product-detail-wrapper {

    div.product-detail {
      padding: 15px 10px;
      
      div.product-detail-header {
        align-items: center;

        .expand-btn-wrapper {
          .expand-btn-label {
            display: none;
          }

          .material-symbols-rounded {
            margin-left: 5px;
          }
        }

        div.product-detail-header-content {
          align-items: center;
  
          .title {
            font-size: 16px;
            line-height: 1em;
            height: auto;
          }
        }
      }
    }
  }
}
aside.menu-sidebar {
	ul {
		list-style: none;
		li {
			padding-left: 1rem;
			background: no-repeat 0 6px;
			transition: all 0.2s ease-out 0s;
			&.active,
			&:hover {
				background-position: 100% 6px;
				> a {
					@include theme(color, link-idle);
				}
			}
			a {
				font-size: 13px;
				@include theme(color, text-primary);
			}
		}
	}
}

.hero-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 440px;
  overflow: hidden;

  .text-block .rich_text {
    margin: 0;
  }

  &.text-white p, &.text-white h1, &.text-white h2, &.text-white h3, &.text-white h4, &.text-white h5, &.text-white h6,
  &.text-white .rich_text p, &.text-white .rich_text h1, &.text-white .rich_text h2, &.text-white .rich_text h3, &.text-white .rich_text h4, &.text-white .rich_text h5, &.text-white .rich_text h6 {
    @include theme(color, cb-text-white);
  }

  &.text-black p, &.text-black h1, &.text-black h2, &.text-black h3, &.text-black h4, &.text-black h5, &.text-black h6,
  &.text-black .rich_text p, &.text-black .rich_text h1, &.text-black .rich_text h2, &.text-black .rich_text h3, &.text-black .rich_text h4, &.text-black .rich_text h5, &.text-black .rich_text h6 {
    @include theme(color, cb-text-black);
  }

  &.text-primary p, &.text-primary h1, &.text-primary h2, &.text-primary h3, &.text-primary h4, &.text-primary h5, &.text-primary h6,
  &.text-primary .rich_text p, &.text-primary .rich_text h1, &.text-primary .rich_text h2, &.text-primary .rich_text h3, &.text-primary .rich_text h4, &.text-primary .rich_text h5, &.text-primary .rich_text h6 {
    @include theme(color, cb-text-primary);
  }

  &.text-secondary p, &.text-secondary h1, &.text-secondary h2, &.text-secondary h3, &.text-secondary h4, &.text-secondary h5, &.text-secondary h6,
  &.text-secondary .rich_text p, &.text-secondary .rich_text h1, &.text-secondary .rich_text h2, &.text-secondary .rich_text h3, &.text-secondary .rich_text h4, &.text-secondary .rich_text h5, &.text-secondary .rich_text h6 {
    @include theme(color, cb-text-secondary);
  }

  .hero-img {
    display: flex;
    align-items: center;
    justify-content: left;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 50vw;
    overflow: hidden;
    
    img {
      width: auto;
      height: 100%;
      max-height: 100%;
      max-width: inherit;
    }
    
  }

  .container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    min-height: 440px;

    .hero-content {
      width: 50%;
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .hero-wrapper {
    display: block;
    min-height: inherit;

    .container {
      display: block;
      min-height: inherit;
      padding: 1rem 0;

      .hero-content {
        width: 100%;
      }
    }
    
    .hero-img {
      display: block;
      position: relative;
      width: 100%;
      left: 0;
      right: 0;
      clear: both;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}
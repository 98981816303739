.mobile-nav {
  position: relative;
}

.mobile-header {
  height: 80px;
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  
  .mobile-logo {
    display: block;
    img {
      max-height: 50px;
    }
    a {
      display: flex;
    }
  }

  .mobile-header-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .telephone {
      margin-right: 20px;
      
      .material-symbols-rounded {
        display: block;
        font-size: 30px;
        @include theme(color, cb-primary-main);
        font-variation-settings:
        'FILL' 1,
        'wght' 700,
        'GRAD' 200,
        'opsz' 48
      }
    }

    .menu-toggle {
      display: inline-block;
      position: relative;
      right: 0;
      cursor: pointer;
    
      .material-symbols-rounded {
        display: block;
        font-size: 36px;
        @include theme(color, cb-primary-main);
        font-variation-settings:
        'FILL' 0,
        'wght' 700,
        'GRAD' 200,
        'opsz' 48
      }
    }
  }  
}

.mobile-panel {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  height: 0;
  width: 100vw;
  z-index: 9999;
  transition: height 0.3s linear;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);

  &.open {
    transition: height 0.3s linear;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .mobile-panel-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding: 10px;
    height: 80px;
    border-bottom: 1px solid;
    @include theme(border-color, border);
    @include theme(background-color, bg-primary);

    ul {
      display: flex;
      align-items: center;
    }

    .language-switcher {
      display: flex;
      align-items: center;
      line-height: 36px;

      a {
        display: inline-flex;
        line-height: 36px;
        @include theme(color, cb-primary-main);
        font-weight: 600;

        span.material-symbols-rounded {
          @include theme(color, cb-primary-main);
          line-height: 36px;
          margin-right: 5px;
          font-variation-settings:
          'FILL' 0,
          'wght' 400,
          'GRAD' 0,
          'opsz' 48
        }
      }
    }
  }

  .mobile-menu-close {
    display: flex;
    justify-self: flex-end;
    @include theme(color, cb-primary-main);

    span {
      font-size: 36px;
    }    
  }

  #mobile-navigation {
    > ul {
      @include theme(border-color, cb-border-default);
      border-bottom: 1px solid;
      margin-bottom: 20px;
    
      > li {
        display: block;
        width: 100%;
        transition: all 0.3s linear;
        text-align: left;
        border-top: 1px solid;
        @include theme(border-color, cb-border-default);

        &:first-child {
          border-top: none;
        }

        &.active {
          @include theme(background-color, cb-light-gray);
          padding-bottom: 10px;

          div.sub-menu-content {
            display: block;
            height: auto;

            .header-container {
              max-width: inherit;
              padding: 0;
              margin: 0;
              display: block;
            }
          }

          > div {
            > button {
              > span {
                
                &.expand-icon-open {
                  display: none;
                }
                &.expand-icon-close {
                  display: inline;
                }
              }
            }
          }
        }

        > div {
          > button,
          > a {
            width: 100%;
            padding: 0 20px;
            border: none;
            background: none;
            line-height: 50px;
            font-weight: 700;
            font-size: 18px;
            color: #333333;
            cursor: pointer;
            text-align: left;
          }

          > button {
            display: flex;
            justify-content: space-between;
            font-family: 'Inter', Arial, sans-serif;

            > span {
              line-height: 50px;
              @include theme(color, cb-primary-main);

              &.material-symbols-rounded {
                line-height: 50px;
                font-size: 36px;
                font-variation-settings:
                'FILL' 0,
                'wght' 600,
                'GRAD' 0,
                'opsz' 36
              }
              
              &.expand-icon-close {
                display: none;
              }
            }
          }
        }

        div.sub-menu-content {
          display: none;
          padding: 0 20px;

          .menu-col {
            padding-bottom: 20px;
          }

          .menu-col-title {
            margin-bottom: 10px;
            h4 {
              display: block;
              font-size: 14px;
              font-weight: bold;
              padding-bottom: 4px;
              @include theme(color, cb-text-secondary);
              @include theme(border-bottom, cb-border-default, 1px solid);
            }
          }
        }

        a {
          font-weight: bold;
          @include theme(color, cb-text-primary);
        }

        li.second-level-item {
          display: flex;
          align-items: center;
          padding-left: 10px;

          a {
            display: inline;
            font-weight: normal;
            font-size: 16px;
            line-height: 30px;
          }

          span {
            @include theme(color, cb-primary-main);
            line-height: 30px;

            &.material-symbols-rounded {
              line-height: 30px;
              font-variation-settings:
              'FILL' 0,
              'wght' 600,
              'GRAD' 0,
              'opsz' 30
            }
          }
        }
      }
    }
  }

  div.search-and-phone {
    display: flex;
    align-items: center;
    padding: 20px;
    text-align: center;
    justify-content: center;

    span.telephone {
      line-height: 30px;
      display: flex;
      a {
        display: flex;
        align-items: center;
        font-weight: bold;
        @include theme(color, cb-primary-main);
      }
      .material-symbols-rounded {
        display: inline-block;
        font-size: 30px;
        margin-right: 10px;
        @include theme(color, cb-primary-main);
        font-variation-settings:
        'FILL' 1,
        'wght' 700,
        'GRAD' 200,
        'opsz' 48
      }
    }
  }

  div.second-menu {
    padding: 0 20px;

    ul {
      li {
        display: flex;
        align-items: center;
        line-height: 30px;

        a{
          line-height: 30px;
          @include theme(color, cb-text-primary);
        }

        .material-symbols-rounded {
          font-size: 24px;
          line-height: 30px;
          @include theme(color, cb-primary-main);
          font-variation-settings:
          'FILL' 0,
          'wght' 600,
          'GRAD' 0,
          'opsz' 30
        }
      }
    }
  }

  .cta-menu-wrapper {
    .cta-menu {
      height: auto;
      padding: 0 20px;
      margin-top: 20px;
      .cta-menu-shadow {
        margin: 0;
      }

      button {
        width: 100%;
      }
    }
  }
}



@media (max-width: 1279px) {
  div.main-header {
    .desktop-nav {
      display: none;
    }
    .secondary-menu-wrapper {
      display: none;
    }

    div.container {

      nav#navigation,
      div.menu-extras {
        display: none;
      }

      div.header-logo {
        max-height: 90px;
        width: 50%;

        img {
          margin: 15px 15px 15px 0;
        }
      }

      .cta-menu-wrapper {
        display: block;
        padding: 0;
        text-align: center;
        border-bottom: 1px solid;
        @include theme(border-color, border);

        .cta-menu {
          display: flex;
          justify-content: center;
          margin: 15px 0;
          min-height: 50px;
          height: auto;
          padding: 0;

          button {
            margin: 0;
          }
        }

        .cta-menu-shadow {
          margin-top: 0;
          box-shadow: none;

        }

        .cta-menu-dropdown {
          text-align: center;

          a {
            visibility: hidden;
          }

          &.open {
            padding: 10px 15px;

            a {
              visibility: visible;
            }

          }
        }
      }
    }
  }
}
// Utilities
// Définitions de classes utilitaires comme marges

body.ie {
	padding-top: 40px;
	position: relative;
}

#ieWarning {
	display: block;
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	height: 40px;
	line-height: 38px;
	text-align: center;
	background-color: #fdf3f3;
	border: 2px solid #e02020;

	p {
		color: #e02020;
	}
}

.visually-hidden {
	display: none;
}
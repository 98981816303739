// Base
// fix for anchor offset when using sticky header
@media screen and (min-width: 1280px) {
  html {
    scroll-padding-top: 220px;
  }
}

@media screen and (max-width: 1279px) {
  html {
    scroll-padding-top: 100px; 
  }
}

div.svg-sprite {
  display: none;
}

//Spacing
$space-type-class: "m", "p";
$space-type: "margin", "padding";
$space-size: 0.25rem, 0.5rem, 1rem, 2rem, 4rem;
$space-size-mobile: 0.25rem, 0.5rem, 1rem, 2rem, 2rem;
$space-pos: "y", "x", "r", "l", "t", "b", "a";

@media (min-width: 769px) {
  @for $i from 1 through length($space-type-class) {
    @for $y from 1 through length($space-size) {
      @each $pos in $space-pos {
        .#{nth($space-type-class,$i)}-#{$y}-#{$pos} {
          @if $pos == "y" {
            #{nth($space-type,$i)}-top: nth($space-size, $y);
            #{nth($space-type,$i)}-bottom: nth($space-size, $y);
          } @else if $pos == "x" {
            #{nth($space-type,$i)}-right: nth($space-size, $y);
            #{nth($space-type,$i)}-left: nth($space-size, $y);
          } @else if $pos == "r" {
            #{nth($space-type,$i)}-right: nth($space-size, $y);
          } @else if $pos == "l" {
            #{nth($space-type,$i)}-left: nth($space-size, $y);
          } @else if $pos == "t" {
            #{nth($space-type,$i)}-top: nth($space-size, $y);
          } @else if $pos == "b" {
            #{nth($space-type,$i)}-bottom: nth($space-size, $y);
          } @else if $pos == "a" {
            #{nth($space-type,$i)}: nth($space-size, $y);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  @for $i from 1 through length($space-type-class) {
    @for $y from 1 through length($space-size-mobile) {
      @each $pos in $space-pos {
        .#{nth($space-type-class,$i)}-#{$y}-#{$pos} {
          @if $pos == "y" {
            #{nth($space-type,$i)}-top: nth($space-size-mobile, $y);
            #{nth($space-type,$i)}-bottom: nth($space-size-mobile, $y);
          } @else if $pos == "x" {
            #{nth($space-type,$i)}-right: nth($space-size-mobile, $y);
            #{nth($space-type,$i)}-left: nth($space-size-mobile, $y);
          } @else if $pos == "r" {
            #{nth($space-type,$i)}-right: nth($space-size-mobile, $y);
          } @else if $pos == "l" {
            #{nth($space-type,$i)}-left: nth($space-size-mobile, $y);
          } @else if $pos == "t" {
            #{nth($space-type,$i)}-top: nth($space-size-mobile, $y);
          } @else if $pos == "b" {
            #{nth($space-type,$i)}-bottom: nth($space-size-mobile, $y);
          } @else if $pos == "a" {
            #{nth($space-type,$i)}: nth($space-size-mobile, $y);
          }
        }
      }
    }
  }
}

html {
  scroll-behavior: smooth;
}

@at-root body {
  line-height: 1.72;
  @include theme(color, fg-secondary);
  box-sizing: border-box;
  > div:first-child {
    display: none;
  }
}
a {
  text-decoration: none;
}

p {
  @include theme(color, fg-secondary);
  margin-bottom: 1rem;
}
li,
ol,
h4 {
  @include theme(color, fg-secondary);
}
li,
ol {
  margin-bottom: 5px;
}

ol {
  counter-reset: myOrderedListItemsCounter;
}
ol li {
  list-style-type: none;
  position: relative;
}
ol {
  padding-left: 15px;
  li {
    margin-left: 20px;
  }
  > li:before {
    position: relative;
    top: 2px;
    display: inline-block;
    counter-increment: myOrderedListItemsCounter;
    content: counter(myOrderedListItemsCounter);
    margin: 10px 10px 0 -35px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    @include theme(background-color, primary-main);
  }
}

body {
  @include theme(background-color, bg-primary);
}

body.sticky {
  padding-top: 100px;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  content: "";
  display: table;
  clear: both;
  zoom: 1;
}

.container {
  margin: 0px auto;
  padding: 0px 15px;
  width: 100%;
  max-width: 1200px;
  &.container-large {
    max-width: 1400px;
  }
}

@media (max-width: 320px) {
  .container {
    margin: 0px auto;
    padding: 0;
    width: 100%;
    max-width: 320px;
    &.container-large {
      max-width: 320px;
    }
  }
}

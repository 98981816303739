$colPadding: 0, 0.25rem, 1rem, 1rem, 1.5rem, 3rem;
$colPadding-length: length($colPadding);
$bgImgSize: auto, contain, cover;
$posY: default, top, center, bottom;
$posX: default, left, center, right;
.content-area {
	position: relative;
	z-index: 1;
	background-position: center right;
	background-size: cover;
	background-repeat: no-repeat;
	&.has-icon {
		padding-top: 0rem;
	}

	&.content-page-with-sidebar {
		margin-top: 20px;

		.container {
			padding: 0;
		}
	}

	&.bordered-section {
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}
	@for $i from 1 through $background-color-list-length {
		&.bg-#{nth($background-color-list, $i)} {
			@include theme(background-color, nth($background-color-list-theme, $i));
		}
	}

	@for $i from 1 through $background-color-list-length {
		&.#{nth($background-color-list, $i)} {
			@include theme(background-color, nth($background-color-list-theme, $i));
		}
	}
	@for $i from 1 through length($bgImgSize) {
		&.bg-size-#{nth($bgImgSize, $i)} {
			background-size: nth($bgImgSize, $i);
		}
	}
	@for $i from 1 through length($posX) {
		@for $y from 1 through length($posY) {
			&.bg-pos-x-#{nth($posX, $i)} {
				&.bg-pos-y-#{nth($posY, $y)} {
					@if nth($posX, $i) == "default" {
						background-position: 0% nth($posY, $y);
					} @else if nth($posY, $y) == "default" {
						background-position: nth($posX, $i) 0%;
					} @else {
						background-position: nth($posX, $i) nth($posY, $y);
					}
				}
			}
		}
	}

	.container {
		.content-section {
			&:before {
				content: " ";
				display: table;
			}
			.column-container {
				position: relative;
				min-height: 1px;

				.wrapper {
					h2.title {
						font-size: 26px;
						letter-spacing: 3px;
						margin-bottom: 0;
						text-transform: uppercase;
						line-height: 1.25;
						font-weight: 600;
					}

					.section_icon {
						margin-top: -45px;
						width: 77px;
						cursor: pointer;
						margin-right: auto;
						margin-left: auto;
						margin-bottom: 35px;
						text-align: center;
						position: relative;
						z-index: 2;
						align-self: start;

						.icon-wrap {
							display: flex;
							justify-content: center;

							div.theIcon {
								border-radius: 100%;
								svg.icon {
									width: 45%;
									border-radius: 100%;
									padding: 1.2rem;
									overflow: visible;
									use {
										width: 95%;
									}
								}
							}
						}

						.vline-circle {
							border-radius: 50%;
							background-clip: border-box;
							margin: 50px auto 0;
							width: 7px;
							height: 7px;
							position: relative;

							&:before {
								content: "";
								height: 50px;
								width: 1px;
								display: block;
								position: absolute;
								top: -50px;
								left: 3px;
							}
						}
					}

					.underline {
						margin-top: 25px;
						font-size: 0;

						&:before {
							content: "";
							display: inline-block;
							width: 90px;
							height: 4px;
						}
					}

					.section-description,
					.section-title {
						margin-bottom: 35px;
					}

					.section-description {
						margin: 0 0 35px;
					}

					.flex-container {
						display: flex;
						&.align-start{
							justify-content: flex-start;
						}
						&.align-center{
							justify-content: center;
						}
						&.align-end{
							justify-content: end;
						}
						&.align-space-between{
							justify-content: space-between;
						}
						&.align-space-around{
							justify-content: space-around;
						}
						&.align-space-evenly{
							justify-content: space-evenly;
						}
						&.px-0 .flex-item.sidebar {
							padding-right: 2rem;
						}

						&.valign-top {
							align-items: flex-start;
						}

						&.valign-center {
							align-items: center;
						}

						&.valign-bottom {
							align-items: flex-end;
						}

						&.same-height {
							.flex-item {
								align-self: stretch;
							}
						}
						
						@for $i from 1 through 12 {
							.flex-#{$i} {
								width: 100%;
							}
						}

						@for $i from 1 through $colPadding-length {
							&.px-#{$i - 1} {
								.flex-item {
									padding-right: nth($colPadding, $i);
									padding-left: nth($colPadding, $i);
								}
							}
						}
					}
					
					@media (max-width: 768px) {
						.flex-container {
							flex-flow: wrap;
							//flex-direction: column-reverse;
							.flex-item {
								margin-bottom: 20px;
							}

							&.px-0 .flex-item {
								padding: 0 2rem;
							}

							&.px-0 .flex-item.sidebar {
								padding: 0 2rem;

								.flex-item {
									padding: 0;
								}
							}

							&.page{
								flex-direction: column-reverse;
							}
						}
					}
					
					@media (max-width: 599px) {
						.flex-container {
							flex-flow: wrap;
							flex-direction: column;
							.flex-item {
								margin-bottom: 1rem;
							}

							&.px-0 .flex-item {
								padding: 0 1rem;
							}

							&.px-0 .flex-item.sidebar {
								padding: 0 1rem;

								.flex-item {
									padding: 0;
								}
							}
						}
					}

					@media (min-width: 1024px) {
						.flex-container {
							flex-flow: row wrap;

							.flex-item {
								flex-grow: 0;
							}

							@for $i from 1 through 12 {
								.flex-#{$i} {
									width: ($i/12) * 100%;
								}
							}
						}
					}

					@media (min-width: 769px) and (max-width: 1023px) {
						.flex-container {
							flex-flow: row wrap;

							.flex-item {
								flex-grow: 0;
							}

							@for $i from 1 through 12 {
								.flex-#{$i} {
									width: ($i/12) * 100%;
								}
							}

							.flex-3 {
								width: 50%;
								margin-bottom: 30px;
							}
						}
					}
					
					@media (min-width: 0px) and (max-width: 599px) {
						.flex-container {

							@for $i from 1 through 12 {
								.flex-#{$i} {
									width: 100%;
								}
							}

						}
					}
				}
				
				@media (max-width: 599px) {
					.flex-container {
						flex-flow: wrap;
						.flex-item {
							margin-bottom: 1rem;
						}

						&.px-0 .flex-item {
							padding: 0;
						}

						&.px-0 .flex-item.sidebar {
							padding: 0;

							.flex-item {
								padding: 0;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 769px) and (max-width: 940px) {
	.content-area.bg-pos-x-right.bg-pos-y-bottom {
		background-size: 50%;
	}
}

@media (max-width: 768px) {
	.content-area.bg-pos-x-right.bg-pos-y-bottom {
		background-size: 25%;
	}

	.content-page-with-sidebar {
		.content-area
			.container
			.content-section
			.column-container
			.wrapper
			.flex-container.px-3
			.flex-4 {
			padding: 0 !important;
		}
	}
}

@media (max-width: 576px) {
	.content-area.bg-pos-x-right.bg-pos-y-bottom {
		background-size: 35%;
	}
	.content-area{
		.container{
			padding: 0;
		}
	}
}

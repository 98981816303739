div.banner-radius {
	display: flex;
	padding: 20px 70px;
	border-radius: 30px;
	align-items: center;
	justify-content: center;
	@for $i from 1 through $background-color-list-length {
		&.bg-#{nth($background-color-list, $i)} {
			@include theme(background-color, nth($background-color-list-theme, $i));
		}
	}
	img {
		margin-right: 1rem;
		height: 70px;
	}
	.rich_text {
		p {
			font-size: 18px;
			margin-bottom: 0;
		}
		a {
			font-size: 18px;
			text-decoration: underline;
			@include theme(color, common-black);
			&:hover {
				text-decoration: none;
			}
		}
	}
}

@media (max-width: 768px) {
	div.banner-radius {
		padding: 20px 30px;
	}
}

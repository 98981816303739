// Themes
@use "sass:string";

$default: (
        bg-primary: color(white),
        bg-secondary: color(aquaSpring),
        bg-terciary: color(wildSand),
        bg-quaternary: color(alabaster),
        bg-quinary: color(porcelain),
        bg-senary: color(orient),
        bg-septenary: color(pattensBlue),
        bg-octonary: color(seashellPeach),
        fg-primary: color(mineShaft),
        fg-secondary: color(mineShaft),
        fg-terciary: color(white),
        fg-quaternary: color(doveGray),
        fg-quinary: color(gray),
        subscribe-bg: color(pattensBlue),
        subscribe-fg: color(orient),
        subscribe-btn-hover: color(ecstasy),
        link-idle: color(cerulean),
        link-hover: color(goldenTainoi),
        alert-bg: color(creamCan),
        header-fg: color(mineShaft),
        header-fg-secondary: color(white),
        header-fg-hover: color(lochmara),
        border: color(mercury),
        btn1: color(cerulean),
        btn2: color(goldenTainoi),
        btn3: color(blazeOrange),
        pageNavBorder: color(dodgerBlue),
        pageNavIndexBottomBorder: color(seagull),
        pageNavIndex-fg: color(gray),
        footer-bg: color(pattensBlue),
        footer-fg-primary: color(orient),
        footer-fg-secondary: color(gray),
        form-placeholder-color: color(regentGray),
  //-------------Colors new design------------//
  //COULEUR BRAND
        brand: color(cerulean),
  //DIVIDER
        divider: color(alto),
  //COMMON
        common-white: color(white),
        common-black: color(mineShaft),
  //PRIMARY
        primary-dark: color(orient),
        primary-main: color(lochmara),
        primary-main-alpha: color(polar),
        primary-light: color(pattensBlue),
        primary-contrast: color(white),
  //SECONDARY
        secondary-dark: color(ecstasy),
        secondary-main: color(tanHide),
        secondary-light: color(derby),
        secondary-contrast: color(white),
  //ERROR
        error-dark: color(redBerry),
        error-main: color(alizarinCrimson),
        error-light: color(wispPink),
        error-contrast: color(white),
  //WARNING
        warning-dark: color(yukonGold),
        warning-main: color(creamCan),
        warning-light: color(milkPunch),
        warning-contrast: color(mineShaft),
  //INFO
        info-dark: color(genoa),
        info-main: color(pacificBlue),
        info-light: color(whiteIce),
        info-contrast: color(white),
  //SUCCESS
        success-dark: color(camarone),
        success-main: color(eucalyptus),
        success-light: color(tara),
        success-contrast: color(mineShaft),
  //GREY
        grey-900: color(emperor),
        grey-700: color(gray),
        grey-500: color(silver),
        grey-300: color(alto),
        grey-100: color(concrete),
  //TEXT
        text-primary: color(mineShaft),
        text-secondary: color(emperor),
        text-disabled: color(silver),
  //BACKGROUND
        background-paper: color(white),
        background-default: color(wildSand),
        background-secondary: color(lilyWhite),
  //BORDER
        cb-border-default: color(borderColor),

  // Design System Colors
        cb-light-gray: #f6f6f6,
        cb-grey-light: #E6E6E6,
        cb-grey-pale: #F2F2F2,
        cb-blue-main: #077AC0,
        cb-blue-light: #A1DEFC,
        cb-blue-pale: #D2ECFC,
        cb-primary-dark: #003070,
        cb-primary-main: #077AC0,
        cb-primary-hover: #005CAD,
        cb-primary-light: #E1F5FF,
        cb-secondary-dark: #AB5200,
        cb-secondary-main: #E07100,
        cb-secondary-light: #FFEBD7,
        cb-error-dark: #8E0900,
        cb-error-main: #E02020,
        cb-error-light: #FDEEEE,
        cb-warning-dark: #B38719,
        cb-warning-main: #F2C94C,
        cb-warning-light: #FFF7DF,
        cb-info-dark: #147379,
        cb-info-main: #00ACB8,
        cb-info-light: #DFF8FA,
        cb-success-dark: #005826,
        cb-success-main: #219653,
        cb-success-light: #DCF6E7,
  // Design System Text Colors
        cb-text-white: #FFFFFF,
        cb-text-black: #000000,
        cb-text-primary: #333333,
        cb-text-secondary: #4F4F4F,
);

//-----------------------------------------UTILISATION DU MIXIN DE THEME------------------------------------------\\
//-             Pour mettre des couleurs selon le theme ex: @include theme(background, bg-primary);              -\\
//-Définition : @include theme(property, variableDeCouleurDuTheme, valeurAvantLaVariable, valeurApresLaVariable);-\\
//-      Pour faire (box-shadow:  #ffffff;) faire : @include theme(box-shadow, bg-primary, 2px 2px 2px 2px);     -\\
//-       Pour faire (border: solid #ffffff 1px ) faire : @include theme(border, bg-primary, solid, 1px);       -\\
//----------------------------------------------------------------------------------------------------------------\\
/** Génération de thèmes **/
$themes: ();
$themeValues: ();
@include generateThemeMappings(default-theme, $default);

$checkmark: "../asset/resource/svg/check1.svg";

.text-block {
	@for $i from 1 through $background-color-list-length {
		&.bg-#{nth($background-color-list, $i)} {
			@include theme(background-color, nth($background-color-list-theme, $i));
		}
	}
	.rich_text {
		> * {
			@include theme(color, fg-secondary);
		}
	}
	margin-bottom: 20px;
}

.text-block.checklist-style {
	h2,
	h3 {
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 2rem;
		padding-bottom: 10px;
		border-bottom: 1px solid;
		@include theme(border-color, border);
	}
	h3 {
		font-weight: 500;
	}

	.rich_text {
		ul {
			li {
				display: block;
				padding: 15px 0 15px 24px;
				margin: 0;
				line-height: 1.2rem;
				border-bottom: 1px solid;
				background-image: url($checkmark);
				background-repeat: no-repeat;
				background-position: left center;
				background-size: 15px;
				@include theme(border-color, border);
				@include theme(color, fg-quinary);

				&:before {
					display: none;
				}

				&:last-child {
					border: none;
				}
			}
		}
	}
}

.sidebar .rich_text {
	margin-top: 0;
}

//Style for imported articles from old site
.rich_text {
	.img-left {
		float: left;
		margin: 0.5em 20px 0 0;
	}

	.fltl{
		float: left;
	}

	ul.fltl {
		width: 60%;
		min-width: 325px;
	}

	hr.breaker {
		clear: both;
		height: 0;
		margin: 0;
		padding-top: 10px;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

// Animations
@keyframes show-menu {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@keyframes hide-menu {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}
@keyframes bounce {
  0%   {transform: translateY(0);}
  50%  {transform: translateY(.3rem);}
  100%  {transform: translateY(0);}
}




div.product-card-wrapper {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  a.product-card-link:hover {
    div.product-card {
      @include theme(background-color, bg-secondary);
      transition: background-color, box-shadow 0.3s linear;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.35);
    }
  }

  div.product-card {
    display: flex;
    min-height: 100px;
    align-items: stretch;
    @include theme(border, cb-border-default, 1px solid);
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    transition: background-color, box-shadow 0.3s linear;

    &.small {
      div.img {
        padding: 10px;
        img {
          max-width: 36px;
        }
      }

      div.content {
        padding: 10px 10px 10px 0;
        .title {
          font-size: 16px;
          line-height: 22px;
        }

        .desc * {
          font-size: 14px;
        }
      }

      div.link-arrow {
        .material-symbols-rounded {
          font-size: 40px;
        }
      }
    }

    &.rounded-border {
      border-radius: 5px;
    }

    &.highlight-border {
      @include theme(border, cb-border-default, 1px solid);
      @include theme(border-left, cb-primary-main, 6px solid);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &.no-padding {
      div.img {
        padding: 0;

        img {
          max-height: 120px;
          width: auto;
        }
      }

      div.content {
        padding-left: 20px;
      }
    }

    &.with-bg-color {
      div.img {
        @include theme(background-color, bg-secondary);
      }	
    }

    div.img {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;

      img {
        max-height: 64px;
        width: auto;
      }
    }

    &.with-bg-color {
      div.img {
        @include theme(background-color, bg-secondary);
      }
      div.content {
        padding-left: 20px;
      }
    }


    div.content {
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: center;
      flex-direction: column;
      flex: auto;
      padding-right: 20px;

      .title {
        font-size: 20px;
        line-height: 28px;
        align-self: flex-start;
        font-weight: bold;
        margin: 0;
        @include theme(color, cb-text-primary);
      }

      .desc {
        align-self: flex-start;
        margin: 5px 0 0 0;
        
        * {
          @include theme(color, cb-primary-main);
          margin: 0;
        }
      }
    }

    div.link-arrow {
      display: flex;
      align-items: center;
      @include theme(color, cb-primary-main);

      .material-symbols-rounded {
        font-size: 64px;
      }
    }
  }
}

@media (max-width: 1279px) {
  div.product-card-wrapper {
    div.product-card {
      min-height: 100px;

      div.img {
        padding: 10px;
      }

      div.content {
        padding: 10px 10px 10px 0;

        .title {
          font-size: 20px;
        }

        .desc * {
          font-size: 14px;
          line-height: 1.2em;
        }
      }

      &.small {
        div.img {
          padding: 10px;
          img {
            max-width: 40px;
          }
        }
  
        div.content {
          padding: 10px 10px 10px 0;
          .title {
            font-size: 14px;
          }
  
        }
  
        div.link-arrow {
          .material-symbols-rounded {
            font-size: 40px;
          }
        }
      }

      &.with-bg-color {
        div.content {
          padding-left: 10px;
        }
      }
  
    }
  }
}

@media (max-width: 1024px) {
  div.product-card-wrapper {
    div.product-card {
      min-height: 120px;

      div.content {
        .title {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  div.product-card-wrapper {
    margin-bottom: 20px;

    div.product-card {
      min-height: inherit;

      div.img {
        padding: 10px;
  
        img {
          max-width: 48px;
          height: auto;
        }
      }

      div.link-arrow {
  
        .material-symbols-rounded {
          font-size: 40px;
        }
      }
    }
  }
}


// mega menu specific styles
.mega-menu {
  div.product-card-wrapper {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    a.product-card-link:hover {
      div.product-card {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
      }
    }

    div.product-card {
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
      min-height: inherit;

      div.img {
        @include theme(border-right, cb-border-default, 1px solid);

        img {
          max-height: 40px;
          width: auto;
        }	
      }
      div.content {
        padding-left: 10px;
        justify-content: center;
        min-height: 75px;
        .desc {          
          * {
            font-weight: normal;
            @include theme(color, cb-text-primary);
            margin: 0;
            line-height: 1em;
          }
        }
      } 
    }
  }
}

// mobile menu specific styles
.mobile-nav {
  div.product-card-wrapper {
    margin: 10px 0;

    a.product-card-link:hover {
      div.product-card {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
      }
    }

    div.product-card {
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
      min-height: inherit;

      div.img {
        @include theme(border-right, cb-border-default, 1px solid);

        img {
          max-height: 30px;
          width: auto;
        }	
      }
      div.content {
        padding: 0 10px;
        min-height: 70px;

        .title {
            font-size: 14px;
            line-height: 16px;
        }
        .desc {
          margin-top: 5px;
          
          * {
            font-weight: normal;
            font-size: 12px;
            margin: 0;
            line-height: 1.1em;
            @include theme(color, cb-text-primary);
          }
        }
      }

      div.link-arrow {
        .material-symbols-rounded {
          font-size: 24px;
          line-height: 24px;
          font-variation-settings:
          'FILL' 0,
          'wght' 600,
          'GRAD' 0,
          'opsz' 30
        }
      }
    }
  }
}
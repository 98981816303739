.blog-article, article.article-header {
	h1 {
		@include theme(color, fg-secondary);
		font-size: 2rem;
		line-height: 1.3em;
	}

	h2 {
		font-size: 1.65rem;
		line-height: 1.25em;
	}

	p.article-date {
		font-size: 16px;
		margin-bottom: 0;
		@include theme(color, cb-text-secondary);
	}
}

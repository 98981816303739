$font-size-breadcrumb: .8rem;
section.breadcrumb{
    padding: .15rem 0;
    max-width: 1200px;
    padding-left: 15px;
    margin: auto auto 1rem auto;
    ul{
        display: flex;
        list-style: none;
        @include theme(border-bottom, border, 1px solid);
        li{
            margin-bottom: 0;
            &::before{
                content: "/";
                padding: 0 .4rem;
                font-size: $font-size-breadcrumb;
            }
            &:first-child{
                &::before{
                    display: none;
                }
            }
            a{
                @include theme(color, fg-primary);
                font-size: $font-size-breadcrumb;
                &:hover{
                    @include theme(color, btn1);
                    text-decoration: underline;
                    transition: .5s;
                }
            }
        }
    }
}

.column-container{
    section.breadcrumb{
        max-width: auto;
        padding-left: 0;
        margin: 0 0 1rem 0;
    }
} 
aside.subscribe {
	display: flex;
	justify-content: space-around;

	div.info {
		display: flex;
		flex-direction: column;
		max-width: 50%;
		.text-subscribe {
			margin-bottom: 26px;
			p {
				font-size: 17px;
				line-height: 27.2px;
			}
		}
		.text-subscribe-policy {
			font-size: 14px;
			text-align: center;
			margin-top: 26px;
			a {
				text-decoration: underline;
				@include theme(color, primary-main);
				&:hover {
					@include theme(color, secondary-main);
				}
			}
		}
		form {
			text-align: center;
			input[type="email"] {
				width: 260px;
				padding: 12px 10px;
				font-size: 14px;
				margin: 0 20px 0 0;
				border-radius: 4px;
				@include theme(border, grey-500, solid 2px);
			}
			input[type="submit"] {
				margin: 0;
				padding: 0.75rem 1.25rem;
				border-radius: 4px;
				font-size: 1rem;
				height: auto;
				display: inline-flex;
				align-items: center;
				border: 0px solid black;
				cursor: pointer;
				@include theme(background-color, primary-main);
				@include theme(color, primary-contrast);
				&:hover {
					@include theme(background-color, primary-dark);
					@include theme(color, primary-contrast);
				}
				svg {
					margin-right: 0.5rem;
					width: 1.2rem;
				}
				&.full-width {
					display: flex;
					justify-content: center;
				}
			}
		}
	}
}

@media (min-width: 769px) and (max-width: 940px) {
	aside.subscribe div.info form input[type="email"] {
		width: 50%;
		margin-right: 20px;
	}
}

@media (max-width: 768px) {
	aside.subscribe {
		display: block;
		clear: both;
		text-align: center;
		width: 100%;
		padding: 0;
		.rich-title {
			margin-bottom: 30px;

			h3 {
				margin: 0;
			}
		}

		div.info {
			max-width: inherit;
			width: 100%;

			form {
				input[type="email"] {
					margin: 0 0 20px 0;
				}
			}
		}
	}
}

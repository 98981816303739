@import "abstracts/variables";
@import "abstracts/functions";
@import "abstracts/mixins";

@import "base/_themes";

@import "base/reset";

@import "base/animations";
@import "base/base";
@import "base/typography";
@import "base/utilities";
@import "base/backgrounds";
@import "base/foregrounds";
@import "base/editor";


//Components
@import "components/components.scss";


/* TODO - Move hotfixes to their component file */
/* HOTFIX */

header a,
header button {
    font-family: 'Inter', Arial, sans-serif !important;
}


/* HEADER SECONDARY MENU */
.default-theme div.main-header .secondary-menu-wrapper {
    border: 0;
    background-color: #F7F6F6;
}

.default-theme div.main-header .secondary-menu-wrapper div.second-menu ul li.language-switcher a,
.default-theme div.main-header .secondary-menu-wrapper div.second-menu ul li.language-switcher span.material-symbols-rounded {
    color: #003070;
}

div.main-header .secondary-menu-wrapper div.second-menu ul li,
div.main-header .secondary-menu-wrapper div.second-menu ul li a {
    line-height: 36px;
    height: 36px;
}


/* HEADER MAIN MENU */
.default-theme div.main-header div.header-container nav#navigation>ul>li.active {
    border: 0;
    background-color: #F7F6F6;
}

/* MEGA MENU */
div.main-header div.header-container nav#navigation>ul>li div.mega-menu .header-container .menu-col.featured,
.default-theme div.main-header div.header-container nav#navigation>ul>li div.mega-menu .header-container .menu-col.featured {
    border-top: 0;
    border-bottom: 0;
    border-radius: 0;
}

.mega-menu div.product-card-wrapper a.product-card-link div.product-card {
    padding: 10px;
}

.mega-menu div.product-card-wrapper a.product-card-link div.product-card,
.mega-menu div.product-card-wrapper a.product-card-link:hover div.product-card {
    box-shadow: none;
}

div.main-header div.header-container nav#navigation>ul>li div.mega-menu .header-container .menu-col {
    padding: 15px 30px;
}

div.main-header div.header-container nav#navigation>ul>li div.mega-menu .header-container .menu-col .menu-col-title span {
    line-height: inherit;
    padding-bottom: 5px;
}

div.main-header div.header-container nav#navigation>ul>li div.mega-menu .header-container .menu-col ul li.second-level-component {
    margin-bottom: 15px;
}

div.product-card-wrapper div.product-card.small div.img {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
}

.default-theme .mega-menu div.product-card-wrapper div.product-card div.img {
    border: 0;
}

.default-theme div.product-card-wrapper div.product-card.with-bg-color div.img {
    background-color: transparent;
}

.mega-menu div.product-card-wrapper div.product-card div.img img {
    max-width: inherit;
    max-height: inherit;
}

div.product-card-wrapper div.product-card.small div.content {
    padding: 0 10px;
    min-height: inherit;
}

.default-theme div.product-card-wrapper div.product-card div.content .title {
    color: #077AC0;
}


/* HEADER CTAs */

/*_menu-mobile.scss:34*/
.default-theme .cta-menu button {
    background-color: #077AC0;
    border: 2px solid #077AC0;
    color: #FFFFFF;
}

.default-theme .cta-menu2 button {
    background-color: white;
    border: 2px solid #003070;
    color: #003070;
}

.default-theme .cta-menu .cta-menu-shadow {
    border-radius: 8px !important;
    background-color: transparent !important;
}

.cta-menu button,
.cta-menu2 button {
    font-size: 18px;
    border-radius: 8px !important;
    line-height: 46px;
}

.cta-menu button .arrow-down,
.cta-menu button .arrow-up,
.cta-menu2 button .arrow-down,
.cta-menu2 button .arrow-up {
    display: none !important;
}

.cta-menu2 .cta-menu2-dropdown {
    transition: all 0.1s linear !important;
}

.cta-menu .cta-menu-dropdown.open,
.cta-menu2 .cta-menu2-dropdown.open {
    height: auto !important;
    margin-top: 5px;
    padding: 15px;
    border-radius: 8px;
    border: 2px solid #077AC0 !important;
    transition: all 0.1s linear !important;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
}

.cta-menu2 .cta-menu2-dropdown.open {
    border: 2px solid #003070 !important;
}

.cta-menu2 .cta-menu2-dropdown.open a {
    color: #003070;
}

.cta-menu .cta-menu-dropdown li,
.cta-menu2 .cta-menu2-dropdown li {
    line-height: 44px !important;
}


.cta-menu .cta-menu-dropdown a,
.cta-menu2 .cta-menu2-dropdown a {
    font-size: 16px;
    line-height: 44px;
    font-weight: bold;
}

.mobile-nav .cta-menu2 {
    height: inherit;
    padding: 0 20px;
}

.mobile-nav .cta-menu2 .cta-menu2-btn {
    width: 100%;
}

.mobile-nav .second-menu {
    margin-top: 30px;
}

.mobile-nav .cta-menu .cta-menu-dropdown a,
.mobile-nav .cta-menu2 .cta-menu2-dropdown a {
    font-size: 18px;
}

.mobile-panel #mobile-navigation>ul>li div.sub-menu-content .menu-col-title span {
    font-weight: 500;
    padding-bottom: 3px;
    border-bottom: 3px solid #077AC0;
}

.mobile-panel #mobile-navigation>ul>li li.second-level-item {
    padding-left: 0;
}

/* CONTENT CTA */
div.cta-container a.btn,
div.cta-container input[type="submit"].btn {
    border-radius: 12px;
    height: 72px;
    font-size: 20px;
}
.modal-backdrop {
	display: none;
}

.modal {
	width: 50vw;
	height: 60vh;
	position: relative;
	top: 20vh;
	padding-bottom: 70px;
	margin: 0 auto;

	background: #ffffff;

	.modal-title {
		position: relative;
		z-index: 11;
		width: 100%;
		padding: 0 2rem;
		display: flex;
		align-items: center;
		height: 60px;
		@include theme(background-color, footer-bg);
		font-size: 24px;
		font-weight: 100;
	}

	.modal-content {
		height: calc(60vh - 130px);
		padding: 2rem;
		font-size: 18px;
		font-weight: 300;
		overflow-y: scroll;
	}

	.modal-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 70px;
		padding-top: 20px;
		text-align: center;
		background: white;

		.modal-close {
			margin-top: 10px;
			height: 50px;
			cursor: pointer;

			&:focus {
				outline: 2px solid #000;
			}
		}
	}
}

.modal-open {
	overflow: hidden;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 1;

	.modal-backdrop.modal-is-open {
		display: block;
		position: fixed;
		z-index: 10;
		height: 100vh;
		width: 100vw;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.6);
	}
}

@media (max-width: 1024px) {
	.modal {
		width: 80vw;
		height: 60vh;
		top: 20vh;
	}
}

@media (max-width: 768px) {
	body .modal {
		width: 90vw;
		height: 70vh;
		top: 20vh;

		.modal-content {
			height: calc(70vh - 130px);
		}
	}
}

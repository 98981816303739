.cb-accordion {
	background: none;

	&.active {
		.panel {
			@include theme(border-bottom, cb-border-default, 1px solid);
		}
	}

	&:first-child {
		@include theme(border-top, cb-border-default, 1px solid);
	}

	&.bordered {
		@include theme(border-left, cb-border-default, 1px solid);
		@include theme(border-right, cb-border-default, 1px solid);
	}

	.cb-accordion-button {
		background: none;
		cursor: pointer;
		padding: 18px;
		width: 100%;
		border: none;
		text-align: left;
		outline: none;
		transition: 0.4s;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		
		@include theme(border-bottom, cb-border-default, 1px solid);
		@include theme(color, primary-main);
		.icon-wrapper {
			display: inline-block;
			height: 12px;
			width: 12px;
			margin-right: 20px;
			svg {
				height: 12px;
				width: 12px;
				use {
					@include theme(fill, primary-main);
				}
			}
		}
		.label{
			font-size: 1rem;
			font-family: 'Inter', Arial, sans-serif;
		}
		&:hover,
		&.active {
			@include theme(background-color, primary-main-alpha);
			@include theme(color, primary-dark);
			svg {
				use {
					@include theme(fill, primary-dark);
				}
			}
		}
		&.active {
			svg {
				transform: rotate(90deg);
			}
		}
	}
	.panel {
		background-color: transparent;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;

		.panel-padding {
			padding: 20px;
		}
	}
	&.edit {
		.panel {
			max-height: none !important;
		}
	}
}

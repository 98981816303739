section.infos-destinations{
    input{

    }
    div.list-destinations{
        ul{
           display: flex; 
           flex-wrap: wrap;
           justify-content: space-between;
           list-style: none;
           
            li{
                width: 30%;
                display: flex;
                padding: 1rem 0;
                &.hide{
                    display: none;
                }
                a{
                    min-width: 100%;
                    display: flex;
                    background-position: center;
                    background-size: cover;
                    &:hover{
                        span{
                            @include theme(color, link-idle)
                        }
                    }
                    span{
                        margin-top: 5rem;
                        padding-left: 1rem;
                        width: 100%;
                        background-color: rgba($color: #ffffff , $alpha: .7);
                        @include theme(color, fg-quaternary)
                    }
                }
            }
        }
    }
}
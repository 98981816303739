// common
.bg-white{
  background-color: white;
}
.bg-blue {
  @include theme(background-color, cb-primary-main);
}
.bg-light-blue {
  @include theme(background-color, cb-primary-light);
}

.bg-dark-blue {
  @include theme(background-color, cb-primary-dark);
}

.bg-blue-wcag {
  @include theme(background-color, cb-primary-main);
}

.bg-blue-main {
  @include theme(background-color, cb-primary-main);
}

.bg-blue-light {
  @include theme(background-color, cb-blue-light);
}

.bg-blue-pale {
  @include theme(background-color, cb-blue-pale);
}

.bg-light-gray {
  @include theme(background-color, cb-light-gray);
}

.bg-grey-light {
  @include theme(background-color, cb-grey-light);
}

.bg-grey-pale {
  @include theme(background-color, cb-grey-pale);
}

// primary
.bg-primary-dark{
  @include theme(background-color, cb-primary-dark);
}
.bg-primary-main {
  @include theme(background-color, cb-primary-main);
}
.bg-primary-light {
  @include theme(background-color, cb-primary-light);
}

// secondary
.bg-secondary-dark {
  @include theme(background-color, cb-secondary-dark);
}
.bg-secondary-main {
  @include theme(background-color, cb-secondary-main);
}
.bg-secondary-light {
  @include theme(background-color, cb-secondary-light);
}

// info
.bg-info-dark {
  @include theme(background-color, cb-info-dark);
}
.bg-info-main {
  @include theme(background-color, cb-info-main);
}
.bg-info-light {
  @include theme(background-color, cb-info-light);
}

// success
.bg-success-dark {
  @include theme(background-color, cb-success-dark);
}
.bg-success-main {
  @include theme(background-color, cb-success-main);
}
.bg-success-light {
  @include theme(background-color, cb-success-light);
}

// error
.bg-error-dark {
  @include theme(background-color, cb-error-dark);
}
.bg-error-main {
  @include theme(background-color, cb-error-main);
}
.bg-error-light {
  @include theme(background-color, cb-error-light);
}

// warning
.bg-warning-dark {
  @include theme(background-color, cb-warning-dark);
}
.bg-warning-main {
  @include theme(background-color, cb-warning-main);
}
.bg-warning-light {
  @include theme(background-color, cb-warning-light);
}
/* Nom des couleurs pris en utilisant         */
/* le projet https://chir.ag/projects/name-that-color */
$colors: (
	goldenTainoi: #fec64d,
	seaBuckthorn: #f7963f,
	treePoppy: #ff9e1b,
	mercury: #e5e5e5,
	chambray: #3b5998,
	deepCerulean: #0077b5,
	dodgerBlue: #4c90fe,
	seagull: #7fcbed,
	cardinal: #d32223,
	doveGray: #666666,
	blazeOrange: #fa6400,
	aquaSpring: #e8f5fd,
	black: #000000,
	tundora: #4c4c4c,
	porcelain: #fcfdfd,
	seashellPeach: #fff5ef,
	//-------------Design System / Style guide------------//
	borderColor: #e0e0e0,


	//-------------Colors new design------------//

	//COULEUR BRAND
	cerulean: #077ac0,

	//DIVIDER
	alto: #e0e0e0,

	//COMMON
	white: #ffffff,
	mineShaft: #333333,

	//PRIMARY
	orient: #00588a,
	lochmara: #005cad,
	polar: #f5fafd,
	pattensBlue: #e1f5ff,

	//SECONDARY
	ecstasy: #f6891b,
	tanHide: #f9a652,
	derby: #ffebd7,

	//ERROR
	redBerry: #8e0900,
	alizarinCrimson: #e02020,
	wispPink: #fdeeee,

	//WARNING
	yukonGold: #866605,
	creamCan: #f2c94c,
	milkPunch: #fff4d3,

	//INFO
	genoa: #147379,
	pacificBlue: #00acb8,
	whiteIce: #dff8fa,

	//SUCCESS
	camarone: #005826,
	eucalyptus: #219653,
	tara: #dcf6e7,

	//GREY
	gray: #828282,
	silver: #bdbdbd,
	concrete: #f2f2f2,
	//TEXT

	emperor: #4f4f4f,
	//BACKGROUND
	alabaster: #fbfbfb,
	wildSand: #f6f6f6,
	lilyWhite: #e3f7ff,
	cararra: #eae7e2,


);

/*****************************************************/

$background-color-list: "white", "light-blue", "light-gray", "blue";
$background-color-list-theme: "background-paper", "background-secondary",
	"background-default", "primary-main";
$background-color-list-length: length($background-color-list);

$fg-color-list: "yellow", "black", "blue", "grey", "white";
$fg-color-list-theme: "secondary-main", "common-black", "primary-main",
	"grey-700", "common-white";
$fg-color-list-length: length($fg-color-list);

/* Sass Config */
$slides_width: 1200px;
$slides_height: 400px;
$slides_maxheight: calc(100vw / (1200 / 600));
$slides_bg: rgba(0, 0, 0, 0.7);
$slides_blue: #ffb300;
/* End Sass Config */

#slides {
	position: relative;
	padding-bottom: 3rem;
}
#slides,
#slides-items .slide-item,
figure {
	//width: $slides_width;
	max-width: 100%;
	//height: $slides_height;
	//max-height: $slides_maxheight;
	//margin: 0 auto;
}
#slides-items .slide-item {
	//position: absolute;

	figcaption {
		position: absolute;
		bottom: 0;
		width: $slides_width;
		max-width: calc(100% - 6.8rem);
		max-height: calc(100% - 3.4rem);
		overflow: auto;
		padding: 1.7rem 3.4rem;
		text-align: center;
		color: #fff;
		background-color: $slides_bg;

		a {
			color: #fff;

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
			}
		}
	}
}

/* CSS Transition */
#slides-items .slideactive {
	opacity: 1;
	transition: opacity 3s;
	visibility: visible;
}

/* Control Buttons */
.slides-control button {
	background-color: $slides_bg;

	svg,
	g {
		fill: #f6f6f6;
	}
	&:hover,
	&:focus,
	&:active {
		background-color: rgba(255, 255, 255, 0.9);

		svg {
			fill: #474747;
		}
	}
}
#slides {
	.slides-control button {
		position: absolute;
		z-index: 1;
	}
	.slides-prev,
	.slides-next {
		top: calc(50% - 3rem);
		padding: 0.6rem;

		svg {
			width: 1.1rem;
			height: 1.1rem;
		}
	}
	.slides-prev {
		left: 0;
		border-radius: 0px 7px 7px 0px;
	}
	.slides-next {
		right: 0;
		border-radius: 7px 0px 0px 7px;
	}
	.slides-playpause {
		top: 0;
		right: 0;
		width: 0.7rem;
		height: 0.7rem;
		padding: 0.7rem;
		background-color: $slides_bg;

		svg {
			width: 0.7rem;
			height: 0.7rem;
		}
		.play,
		.playpause.paused .pause {
			display: none;
		}
		.playpause.paused .play {
			display: block;
		}
	}
}
section.slider {
	display: flex;

	aside.navigation-aside {
		width: 30%;
		align-self: center;
		div.rich-title {
			margin-bottom: 35px;
		}
		div.nav {
			display: flex;
			a.slider-nav {
				@media (max-width: 599px) {
					$sizeNav: 40px;
					height: $sizeNav;
					width: $sizeNav;
					padding: .75rem;
				}
				height: 72px;
				width: 72px;
				padding: 1.5rem;
				@include theme(background-color, primary-main);
				border-radius: 100%;
				cursor: pointer;
				&:first-child {
					margin-right: 2rem;
				}
				svg {
					use {
						@include theme(fill, primary-contrast);
					}
				}
			}
		}
	}
	div#slides-items {
		width: 70%;
		overflow: hidden;
		padding: 0.5rem 0;
		div.slide-container {
			width: 10000px;
			display: flex;
			align-items: center;
			transition: 0.5s;
			.slide-item {
				box-sizing: border-box;
				margin: 0 1rem;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			}
		}
	}
	div.slides-dots {
		position: absolute;
		bottom: 0;
		z-index: 1;
		width: $slides_width;
		max-width: 100%;
		text-align: right;
		button {
			width: 16px;
			height: 16px;
			margin: 0 5px;
			border-style: none;
			touch-action: manipulation;
			display: inline-block;
			border: none;
			background: none;
			cursor: pointer;
			padding: 0;
			border-radius: 100%;
			@include theme(border, primary-main, solid, 1px);
			&:hover,
			&:focus,
			&:active,
			&[aria-selected="true"] {
				@include theme(background-color, primary-main);
			}
		}
	}
	&.slider-style-2 {
		flex-direction: column;
		.slider-content {
			max-width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			a {
				width: 50px;
				height: 50px;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				@include theme(background-color, primary-contrast);
				svg {
					width: 40%;
					use {
						@include theme(fill, primary-main);
					}
				}
			}
			.sm-quote {
				background: none;
				.quote-inner-wrap {
					display: flex;
					flex-direction: column-reverse;
				}
			}
		}
		div#slides-items {
			div.slide-container {
				.slide-item {
					box-shadow: none;
				}
			}
		}
		div.slides-dots {
			text-align: center;
			right: 0 !important;
		}
	}
}
.cssForEditor {
	section.slider {
		div#slides-items {
			div.slide-container {
				width: 100%;
				flex-direction: column;
				> * {
					flex-grow: 1;
				}
				.clone {
					display: none;
				}
			}
		}
	}
	#slides-items .slide-item:not(.slideactive) {
		opacity: 1;
		visibility: visible;
	}

	#slides-items .slide-item {
		position: unset;
	}

	#slides,
	#slides-items .slide-item,
	figure {
		height: auto;
		max-height: unset;
	}
}

@media (max-width: 768px) {
	section.slider {
		display: block;

		&#slides {
			padding-bottom: 30px;
		}

		div.slides-dots {
			position: static;
			width: 100%;
			text-align: center;
			margin-top: 20px;
		}

		aside.navigation-aside {
			clear: both;
			width: 100%;
			text-align: center;

			div.nav {
				text-align: center;
				justify-content: center;
				margin-bottom: 30px;
			}
		}

		div#slides-items {
			clear: both;
			width: 100%;
		}
	}
}

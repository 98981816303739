.simple-banner {
	width: 100%;
	@include theme(background, alert-bg);
	text-align: center;
	z-index: 1;
	position: relative;
	display: flex;
	justify-content: center;
	&.hide {
		display: none;
	}
	.simple-banner-text {
		a,
		span {
			display: flex;
			p {
				font-size: 1em;
				padding: 12px 12px;
				@include theme(color, header-fg);
				margin-bottom: 0px;
			}
			svg {
				width: 24px;
				height: auto;
				margin-left: 12px;

				&:last-child {
					width: 1.1rem;
				}
				use {
					@include theme(fill, header-fg);
				}
			}
		}
	}
}
.cssForEditor {
	.simple-banner {
		&.hide {
			display: flex;
		}
	}
}

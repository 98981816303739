.sm-quote {
	min-height: 338px;
	@include theme(background-color, primary-contrast);
	.quote-inner-wrap {
		padding: 60px;
		color: #3a424a;
		.quote-profil {
			display: flex;
			align-items: center;
			padding-bottom: 30px;
			img {
				width: 120px;
				margin-right: 10px;
			}
		}
		.quote-content p {
			margin-bottom: 20px;
			font-size: 14px;
			font-style: italic;
		}
	}
}

@media (max-width: 768px) {
	section.slider.slider-style-2 {
		.slider-content {
			display: block;

			.sm-quote {
				.quote-inner-wrap {
					padding: 20px;

					.quote-profil img {
						margin-right: 20px;
					}
				}
			}

			a.slider-nav {
				display: none;
			}
		}
	}
}

@media (max-width: 599px) {
	.sm-quote {
		.quote-inner-wrap {
			padding: 30px;
			.quote-profil {
				padding-bottom: 16px;
				img {
					width: 60px;
				}
			}
		}
	}
}
@mixin field-style {
	display: inline-block;
	box-shadow: none;
	@include theme(border, border, solid 1px);
	border-radius: 4px;
	box-sizing: border-box;
	max-height: 30px;
	&:focus,
	&:focus-visible {
		@include theme(outline, border, solid 1px);
	}
	&::placeholder {
		@include theme(color, border);
		font-size: 0.9rem;
	}
}
div.searchCategory.archive {
	form {
		input,
		select {
			@include field-style;
			border-bottom: 1rem;
		}
		display: flex;
		div {
			margin: 0 0.5rem;
			> * {
				height: 100%;
				transform: translateY(-1px);
			}
		}
		button {
			border: none;
			padding: 0.5rem;
			font-size: 1.5rem;
			@include theme(background-color, btn1);
			@include theme(color, fg-terciary);
			&:hover {
				@include theme(background-color, btn2);
			}
		}
	}
	div.list-group {
		div.result {
			display: flex;
			flex-direction: column;
			&:first-child {
				@include theme(border-top, border, solid 0px);
			}
			.banner {
				a {
					h2 {
						position: absolute;
						bottom: 2rem;
						width: 100%;
						padding: 0.5rem;
						font-size: 2rem;
						@include theme(color, fg-secondary);
					}
					&:hover {
						transition: 0.5s;
						h2 {
							@include theme(color, link-idle);
						}
					}
				}
			}
			h4 {
				//date
				font-size: 0.9rem;
				@include theme(color, fg-quaternary);
			}
			p {
				//excert
				font-size: 1.1rem;
				@include theme(color, fg-quaternary);
			}
			a.btn {
				margin: 1rem 0;
				align-self: flex-end;
			}
		}
	}
}

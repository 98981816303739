table {
	font-weight: thin;

	strong {
		font-weight: bold;
	}

	caption {
		text-align: left;
		margin-bottom: 10px;
		border-bottom: 1px solid;
		@include theme(border-color, border);
		margin: 0;
	}

	tr {
		border-bottom: 1px solid;
		@include theme(border-color, border);

		td,
		th {
			vertical-align: top;
			padding: 10px;
		}
	}
	tbody tr:last-child {
		border: none;
	}
}

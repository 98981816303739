
.additionalmenu{
    .link-list-wrapper{
        max-width: 1440px;
        width:100%;
        margin:0 auto;
        ul{
           margin-bottom: 0px;
           padding: 0 1rem; 
           &.link-list{
                display:flex;
                flex-direction: row;
                li.link-list-item {
                    display: flex;
                    position: relative;
                    justify-content: center;
                    border:0px;
                    
                    a{
                        padding: 17px 54px 17px 16px;
                        text-decoration: none;
                        background: none;
                        font-weight: normal;
                        font-size: 1rem;
                        &:hover{
                            text-decoration: underline;
                            @include theme(color, bg-primary);
                            background: none!important;

                        }

                        // Design System Text Colors
                        &.text-white {
                            @include theme(color, cb-text-white);
                        }
                        &.text-black {
                            @include theme(color, cb-text-black);
                        }
                        &.text-primary {
                            @include theme(color, cb-text-primary);
                        }
                        &.text-secondary {
                            @include theme(color, cb-text-secondary);
                        }
                    }
                    &.active{
                        text-decoration: underline;
                        &::after{
                            $triangle-size: 6px;
                            content: " ";
                            position: absolute;
                            width: 0; 
                            height: 0; 
                            border-left: $triangle-size solid transparent;
                            border-right: $triangle-size solid transparent;
                            z-index: 3;
                            margin-right: 2rem;
                            bottom: -1 * $triangle-size;
                        }
                        a{
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .additionalmenu {
        .link-list-wrapper {
            ul {
                &.link-list{
                    display: block;
                    li.link-list-item {
                        display: block;
                        width: 100%;

                        a {
                            padding: 15px 0;
                        }
                    }
                }

            }
        }
    }
}

div.small-card-container {
	display: flex;
}
body.cssForEditor{
	div.small-card-container {
		display: block;
	}
}
div.small-card {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 12px;
	@include theme(color, common-black);
	@for $i from 1 through $background-color-list-length {
		&.bg-#{nth($background-color-list, $i)} {
			@include theme(background-color, nth($background-color-list-theme, $i));
		}
	}
	div.image-container {
		text-align: center;
		img {
			max-width: 100%;
			width: 100%;
			@for $i from 1 through 10 {
				&.width-#{$i * 10} {
					width: $i * 10%;
				}
			}
		}

		img.img-margin {
			margin-top: 20px;
		}
	}

	div.small-card-content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 20px 20px 20px 20px;

		&.centered-content, &.centered-content div.small-card-link {
			text-align: center;
		}
	}

	div.small-card-text {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	div.small-card-link {
		align-items: flex-end;
		padding: 20px 0 0 0;
		width: 100%;
	}
	h4 {
		font-size: 1.25rem;
		letter-spacing: -0.2px;
		line-height: 24px;
		min-height: 50px;
	}
	p {
		margin: 10px 0;
		font-size: 14px;
		line-height: 19px;
	}
	a {
		font-size: 14px;
		text-decoration: underline;
		@include theme(color, primary-main);
		&:hover {
			@include theme(color, primary-dark);
		}
	}
}

@media (min-width: 769px) and (max-width: 1023px) {
	div.small-card {
		a {
			position: static;
			margin-bottom: 10px;
		}
	}
}

@media (max-width: 768px) {
	div.small-card {
		height: auto;
		margin: 0 auto;

		div.image-container {
			img {
				width: 100%;
				height: auto;
			}
		}

		a {
			position: static;
			margin-bottom: 10px;
		}
	}
}

$type:   "info", "success", "error", "warning";
$color-var-fg:   "info-dark", "success-dark", "error-dark", "warning-dark";
$color-var-fg-hover:   "info-main", "success-main", "error-main", "warning-main";
$color-var-bg:   "info-light", "success-light", "error-light", "warning-light";
$lenght-type: length($type);

div.alertes{
	margin: 2rem 0px;
	padding: 1.5rem;
	width: 100%;
	display: flex;
	align-items: flex-start;
	border-radius: 4px;
	svg{
		width: 2rem;
		min-width: 2rem;
		flex-grow: 0;
	}
	aside{
		flex-grow: 1;
		margin-left: 1rem;
		>*{
			margin-bottom: .5rem;
			&:last-child{
			margin-bottom: 0;
			}
		}
		h5{
			font-weight: 700;
		}
		a{
			font-weight: 600;
			text-decoration: underline;
		}
	}
	@for $i from 1 through $lenght-type {
		&.type-#{nth($type, $i)}{ 
			@include theme(background-color, nth($color-var-bg, $i));
			svg{
				use{
					@include theme(fill, nth($color-var-fg, $i));
				}
			}
			aside{
				h5, p, a{
					@include theme(color, nth($color-var-fg, $i));
				}
				a:hover{
					@include theme(color, nth($color-var-fg-hover, $i));
				}
			}
		}
	}
}

div.social-media{
    display: flex;
    align-items: baseline;
    img{
        width: 18px;
        margin-right: 1rem;
    }
    &.align-left{
        justify-content: flex-start;
    }
    &.align-center{
        justify-content: center;
    }
    &.align-right{
        justify-content: flex-end;
    }
}
@mixin field-style {
	width: 100%;
	margin: 5px 0 15px;
	display: inline-block;
	border: 1px solid;
	border-radius: 4px;
	@include theme(border-color, grey-500);
	box-shadow: none;
	box-sizing: border-box;
	padding: 0 10px;
	&:focus,
	&:focus-visible {
		background-color: none;
	}
	&::placeholder {
		@include theme(color, form-placeholder-color);
		font-size: 0.9rem;
	}
}

// hide magnolia honeypot field
div.form #mgnlhp {
	display: none;
}

div.form {
	form {
		.form-row {
			label {
				font-weight: 300;
				dfn, em {
					color: red;
				}
				span {
					display: inline-block;
					margin-bottom: 10px;
				}
			}
			input {
				@include field-style;
				min-height: 40px;
			}
			input[type="checkbox"] {
				width: auto;
				height: auto;
				min-height: inherit;
				margin-right: 10px;
			}
			select {
				@include field-style;
				min-height: 40px;
			}
			fieldset {
				select {
					@include field-style;
				}
			}
			textarea {
				@include field-style;
			}

			input[type="radio"] {
				width: auto;
				min-height: 0.8rem;
			}
		}
		.button-wrapper {
			input {
				width: 100%;
				height: 40px;
				line-height: 40px;
				margin: 20px 0;
				font-size: 0.9rem;
				font-weight: bold;
				@include theme(background-color, btn1);
				@include theme(color, fg-terciary);
				cursor: pointer;
				border: none;
				border-radius: 4px;
				&:hover {
					filter: brightness(110%);
					transition: 0.5s;
				}
			}
		}
	}
}

// Include Webfont
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Fonts definition
body {
	font-family: 'Inter', Arial, sans-serif;
}

// Base Typo
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
sup,
sub {
	font-family: 'Inter', Arial, sans-serif;
}

h1 {
	@include theme(color, common-black);
	font-size: 45px;
	line-height: 1.2em;
	font-weight: 700;
}

@media (max-width: 599px) {
	h1 {
		font-size: 24px;
		line-height: 32px !important;
	}
}

h2 {
	font-size: 34px;
	line-height: 3rem;
	margin-bottom: 20px;
	font-weight: 600;
}

h2 .span-blue a,
h2 a {
	font-size: 34px;
	@include theme(color, primary-main);

	&:hover {
		@include theme(color, link-hover);
	}
}

h3 {
	font-size: 24px;
	line-height: 1.25em;
	margin-bottom: 10px;
	font-weight: 500;
}

h3 .span-blue a,
h3 a {
	font-size: 24px;
	@include theme(color, primary-main);

	&:hover {
		@include theme(color, link-hover);
	}
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 12px;
}

p {
	font-size: 16px;
	line-height: 1.5rem;

	span.small {
		font-size: 12px;

		a {
			font-size: 12px;
		}
	}

	a.small {
		font-size: 12px;
	}
}

p a {
	@include theme(color, primary-main);

	&:hover {
		@include theme(color, link-hover);
	}
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

a.btn {
	font-weight: bold;
}

@media (max-width: 768px) {
	h1,
	h2,
	h3 {
		margin-bottom: 1rem;
	}

	h2 {
		font-size: 28px;
		line-height: 2rem;
	}
}

.rich_text {
	a {
		@include theme(color, primary-main);

		&:hover {
			text-decoration: none;
		}
	}
}

sup {
	position: relative;
	top: -0.35rem;
	font-size: 60%;
}

sub {
	position: relative;
	bottom: -0.35rem;
	font-size: 60%;
}

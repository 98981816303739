a.pdf-downloader{
    @include theme(color, link-idle);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.1rem;

    &:hover{
        @include theme(color, link-hover);
    }
    img{
        width: 1.3rem;
        padding-top: .2rem;
    }
}

//Accordeon
div.accordion-pdf{

    div.accordion-button{
        padding: 0;
        background: none;
        border: none!important;
        &:hover, &.active{
        background: none!important;
        }
        svg{
            width: 1rem;
            margin-left: .5rem;
            use{
                @include theme(fill, link-hover);
            }
        }
        &.active {
			svg {
				transform: rotate(0deg);
			}
		}
    }
    div.panel{
        background: none!important;
    }
}
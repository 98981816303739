div.embed-video{
    width: 100%;
    position: relative;
    &:before{
        padding-top: 56.25%;
        content: "";
        display: block;
    }
    iframe{
        width: 100%;
        height: 100%;
        top:0;
        position: absolute;
    }
}
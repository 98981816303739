div.cta-container {
  min-height: 54px;

  &.alignement-center,
  &.alignement-left,
  &.alignement-right {
    display: flex;
  }

  &.alignement-center {
    justify-content: center;
  }

  &.alignement-right {
    justify-content: flex-end;
  }

  a.btn,
  input[type="submit"].btn {
    display: inline-flex;
    flex-wrap: wrap;
    align-content: center;
    min-height: 54px;
    padding: 10px 1.25rem;
    border-radius: 27px;
    text-align: center;
    font-size: 1rem;
    @include theme(background-color, cb-primary-main);
    color: white;
    transition: all 0.2s linear;
    box-shadow: 0px 5px 5px 0 rgba(0,0,0,0.15);

    &.style-2 {
      @include theme(color, cb-primary-hover);
      @include theme(border-color, cb-primary-hover);
      border: 2px solid;
      background-color: white;
    }

    &.style-3 {
      @include theme(color, cb-primary-hover);
      background: none;
      padding: none;
      border-radius: none;
      box-shadow: none;
    }

    svg {
      margin-right: 0.5rem;
      width: 1.2rem;
      fill: white;
    }

    span {
      transition: all 0.2s linear;
      transform: scale(1);
      display: flex;

      span.label {
        padding-right: 15px;
      }
    }


    &:hover {
      transition: all 0.2s linear;
      box-shadow: 0px 5px 15px 0 rgba(0,0,0,0.25);
    }

    &.style-1:hover {
      @include theme(background-color, cb-primary-hover);
    }

    &.style-2:hover {
      @include theme(background-color, cb-blue-pale);
    }

    &.full-width {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  input[type="submit"].btn {
    border: 0 solid black;
  }
}

@media (max-width: 768px) {
  div.cta-container.mobile-centered {
    text-align: center;
  }
}


// temporary fix so we dont have to change button style in the CMS for all Heros
.hero div.cta-container {
  a.btn.style-1 {
    @include theme(color, cb-primary-hover);
    @include theme(border-color, cb-primary-hover);
    background-color: white;
    border: 2px solid;

    &:hover {
      @include theme(color, cb-primary-hover);
      @include theme(background-color, cb-blue-pale);
      background-color: white;
    }
  }
}
.package-fees-wrapper {
	margin: 3rem 0;

	.package-fees-title-wrapper {
		text-align: center;

		h2 {
			font-size: 42px;
			font-weight: 600;
			line-height: 44px;
			margin-bottom: 1rem;
		}

		p {
			font-size: 18px;
			color: #666666;
			font-weight: 300;
		}
	}

	.package-cards-wrapper {
		margin: 2rem 0;
		display: flex;

		.package-card {
			flex-grow: 3;
			background-color: white;
			border: 1px solid #ddd;
			padding: 0 25px;
			margin: 50px 0 20px;
			color: #999;
			width: 33.333%;

			.package-card-title {
				line-height: 65px;
				text-align: center;
				border-bottom: 1px solid #ddd;
				margin-bottom: 10px;

				h3 {
					font-size: 27px;
					font-weight: 500;
					color: #666;
				}
			}

			.package-card-price-before {
				font-size: 13px;
				text-align: left;
			}

			.package-card-price {
				color: #fec64d;
				font-size: 68px;
				font-weight: 600;
				text-align: center;
				justify-content: center;
				margin-top: -10px;

				span {
					display: inline-block;
					line-height: 70px;
				}
			}

			.package-card-price-after {
				font-size: 13px;
				text-align: right;
			}

			.package-card-info {
				margin-top: 20px;
			}

			.package-card-destination,
			.package-card-duration,
			.package-card-age {
				text-align: center;
				font-size: 16px;
				line-height: 2.5em;
			}

			.package-card-button {
				text-align: center;
				margin: 50px 0;
			}

			&.featured {
				background-color: #0099dd;
				margin: 0 -1px;
				color: white;

				.package-card-title {
					border-bottom: 1px solid #80b1ff;

					h3 {
						color: white;
					}
				}

				.package-card-price {
					color: white;
					font-size: 90px;
					font-weight: normal;

					span {
						line-height: 100px;
					}
				}

				.package-card-info {
					margin-top: 40px;
				}

				.package-card-button {
					align-self: flex-end;
				}
			}
		}
	}
}

@media (max-width: 992px) {
	.package-fees-wrapper {
		.package-cards-wrapper {
			.package-card {
				.package-card-title {
					h3 {
						font-size: 22px;
					}
				}
				.package-card-price {
					font-size: 48px;
					span {
						line-height: 62px;
						margin-top: 0;
					}
				}

				&.featured {
					.package-card-price {
						color: white;
						font-size: 70px;
						font-weight: normal;

						span {
							line-height: 80px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.package-fees-wrapper {
		margin: 2em 0;

		.package-cards-wrapper {
			display: block;

			.package-card {
				margin: 25px 0;
				width: 100%;

				.package-card-title h3 {
					margin-bottom: 0;
				}

				.package-card-price {
					font-size: 42px;
					span {
						line-height: 62px;
						margin-top: 0;
					}
				}

				.package-card-info {
					margin-top: 20px;
				}

				.package-card-button {
					margin: 25px 0;
				}

				&.featured {
					margin: 25px 0;

					.package-card-price {
						font-size: 52px;
						span {
							line-height: 62px;
							margin-top: 0;
						}
					}

					.package-card-info {
						margin-top: 20px;
					}
				}
			}
		}
	}
}

a.component-img-link {
	display: block;
	width: 100%;

	&.align-center span {
		text-align: center;
	}

	&.align-left span {
		text-align: left;
	}

	&.align-right span {
		text-align: right;
	}
}

a.hover-effect:hover img,
a.hover-effect:hover span {
	filter: brightness(75%);
}

img.component-img {
	max-width: 100%;
	@for $i from 1 through 10 {
		&.width-#{$i * 10} {
			width: $i * 10%;
			&.align-center {
				margin-left: (100% - ($i * 10%)) / 2;
			}
			&.align-right {
				margin-left: 100% - ($i * 10%);
			}
		}
	}
}

span.component-img-link-label {
	display: block;
	margin-top: 1rem;
	@include theme(color, primary-main);
}

@media (max-width: 768px) {
	img.component-img {
		max-width: 100%;

		&.mobile-width-25 {
			width: 25%;
			margin: 0 calc(75% / 2) !important;
		}

		&.mobile-width-50 {
			width: 50%;
			margin: 0 25% !important;
		}

		&.mobile-width-75 {
			width: 75%;
			margin: 0 12.5% !important;
		}

		&.mobile-width-100 {
			width: 100%;
			margin: 0 auto !important;
		}
	}
}

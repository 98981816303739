//hiding the mobile menu on desktop
@media (min-width: 1280px) {

    .mobile-nav,
    .mobile-only {
        display: none !important;
    }
}

#page-content-wrapper {
    header {
        position: sticky;
        top: 0;
        z-index: 1000;
    }
}

body.menu-open {
    overflow: hidden;
}

div.main-header {
    @include theme(background-color, bg-primary);

    div.header-wrapper {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
        position: relative;
        z-index: 10;
    }

    // second menu
    .secondary-menu-wrapper {
        background: #F3F5F6;
        @include theme(border-bottom, cb-border-default, 1px solid);

        .header-container {
            width: 100%;
        }

        div.second-menu {
            text-align: right;
            width: 100%;
            display: flex;
            padding: 0;
            justify-content: end;

            .telephone {
                display: none;
            }

            ul {
                display: flex;

                li {
                    padding-left: 20px;
                    line-height: 30px;

                    &:first-child {
                        padding: 0;
                    }

                    a {
                        @include theme(color, header-fg);
                        line-height: 35px;
                        height: 35px;
                        display: inline-flex;
                        font-size: 14px;
                        font-weight: 500;
                    }

                    &.link-emphasize {
                        a {
                            @include theme(color, header-fg-hover);
                        }
                    }

                    &:hover {
                        a {
                            text-decoration: underline;
                            @include theme(color, header-fg-hover);
                        }

                        &.link-emphasize {
                            a {
                                @include theme(color, link-hover);
                            }
                        }
                    }

                    &.language-switcher {
                        display: inline-flex;
                        align-items: center;

                        span.material-symbols-rounded {
                            @include theme(color, cb-primary-main);
                            line-height: 36px;
                            margin-right: 5px;
                            font-variation-settings:
                                'FILL' 0,
                                'wght' 400,
                                'GRAD' 0,
                                'opsz' 48
                        }

                        a {
                            display: inline-flex;
                            line-height: 36px;
                            @include theme(color, cb-primary-main);
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    &.sticky {
        position: fixed;
        z-index: 420;
        width: 100%;
        top: 0;
    }

    //Override base
    ul {
        list-style: none;

        li {
            margin-bottom: 0;
        }
    }

    svg.icon {
        max-width: 24px;
    }

    div.header-container {
        max-width: 1400px;
        padding: 0 15px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        div.header-logo {
            max-height: 100px;
            display: flex;
            align-items: center;

            a {
                display: contents;
            }

            img {
                height: 44px;
                margin-right: 40px;
            }
        }

        // main navigation
        nav#navigation {
            height: 100px;
            width: 100%;

            // main navigation first level
            >ul {
                display: flex;

                >li {
                    height: 100px;
                    transition: all 0.3s linear;

                    >div>button,
                    >div>a {
                        background: none;
                        display: flex;
                        align-items: center;
                        padding: 0 20px;
                        border: 0;
                        height: 100px;
                        line-height: 100px;
                        font-weight: 700;
                        font-size: 16px;
                        color: #333333;
                        cursor: pointer;
                    }

                    &:hover {
                        background-color: #e8f5fd;
                    }

                    &.active {
                        @include theme(border-bottom, cb-primary-main, 6px solid);

                        >a,
                        >button {
                            text-decoration: none;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    svg {
                        display: none;
                    }

                    svg.mobile {
                        display: none;
                        transition: 0.5s;
                    }

                    div.mega-menu {
                        visibility: hidden;
                        min-height: 300px;
                        background: #f7f6f6;
                        position: absolute;
                        left: 0;
                        right: 0;
                        z-index: 9;
                        box-shadow: 0px 30px 30px -10px rgba(0, 0, 0, 0.2);
                        @include theme(border-top, cb-border-default, 1px solid);
                        @include theme(border-bottom, cb-border-default, 1px solid);

                        * {
                            opacity: 0.5;
                            transition: all 0 linear;
                        }

                        .header-container {
                            padding: 0;

                            .menu-col {
                                margin: 0 15px;
                                padding: 30px;
                                min-height: 300px;
                                -ms-flex: 1;
                                flex: 1;

                                .col-content {
                                    display: flex;
                                    flex-direction: column;
                                }

                                &.featured {
                                    min-width: 500px;
                                    background: #fff;
                                    border-radius: 5px;
                                    border-left: 1px solid #e0e0e0;
                                    border-right: 1px solid #e0e0e0;
                                }

                                .menu-col-title {
                                    margin-bottom: 20px;

                                    span {
                                        width: 100%;
                                        font-size: 20px;
                                        font-weight: bold;
                                        @include theme(border-bottom, cb-primary-main, 6px solid);
                                        padding-bottom: 7px;
                                        line-height: 40px;
                                        height: 40px;
                                        display: inline;
                                    }
                                }

                                ul {
                                    li {
                                        display: block;
                                        height: auto;
                                        font-size: 16px;
                                        font-weight: 600;
                                        line-height: 40px;
                                    }

                                    li.second-level-item {
                                        display: flex;
                                        line-height: 28px;
                                        margin-bottom: 12px;

                                        * {
                                            display: inline-block;
                                            line-height: 24px;
                                        }

                                        a {
                                            color: #333333;

                                            &:hover {
                                                color: #077ac0;
                                            }
                                        }

                                        span {
                                            color: #077ac0;
                                            margin-left: 5px;
                                            display: flex;
                                            align-items: center;
                                        }
                                    }

                                    li.second-level-item:last-child {
                                        margin-bottom: 0;
                                    }

                                    li.second-level-component {
                                        a {
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.submenu-open {
                        background-color: #e8f5fd;

                        div.mega-menu {
                            visibility: visible;

                            * {
                                opacity: 1;
                                transition: all 0.2s linear;
                            }
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        div.menu-extras {
            height: 100px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: auto;

            div.search-and-phone {
                text-align: right;
                line-height: 36px;
                display: flex;
                justify-content: end;
                align-items: center;
                height: 100px;

                form {
                    display: inline-block;
                    height: 36px;
                    margin-right: 15px;

                    input {
                        display: none;
                    }

                    button.search-icon {
                        border: none;
                        background: none;
                        padding: 0;
                        cursor: pointer;

                        span.material-symbols-rounded {
                            @include theme(color, cb-primary-main);
                            font-size: 32px;
                            line-height: 36px;
                            font-variation-settings:
                                'FILL' 1,
                                'wght' 600,
                                'GRAD' 0,
                                'opsz' 48
                        }

                        &:hover {
                            transition: 1s;
                        }
                    }
                }

                span.telephone {
                    line-height: 36px;
                    display: inline-flex;

                    a {
                        line-height: 36px;
                        display: inline-flex;

                        span.material-symbols-rounded {
                            @include theme(color, cb-primary-main);
                            font-size: 32px;
                            line-height: 36px;
                            font-variation-settings:
                                'FILL' 1,
                                'wght' 600,
                                'GRAD' 0,
                                'opsz' 48
                        }

                        &.phone-menu-text {
                            font-size: 18px;
                            line-height: 36px;
                            font-weight: 700;
                            @include theme(color, header-fg-hover);
                        }
                    }
                }
            }
        }

        .accessibility-links {
            height: 36px;
            width: 100%;

            a {
                position: relative;
                left: -5000px;
                width: auto;
                line-height: 36px;

                &:focus {
                    left: auto;
                }
            }
        }

        .mobile-menu {
            display: none;
        }
    }
}

// cta menu
.cta-menu,
.cta-menu2 {
    height: 100px;
    padding-left: 20px;
    overflow: visible;

    .cta-menu-shadow,
    .cta-menu2-shadow {
        min-height: 50px;
        margin-top: 25px;
        position: relative;
        z-index: 12;
        border-radius: 5px;
        display: block;
    }

    button {
        border: none;
        @include theme(background-color, cb-primary-main);
        border-radius: 5px;
        color: white;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 50px;
        cursor: pointer;
        min-width: 200px;
        height: 50px;
        padding: 0 15px;
        text-align: center;

        & {
            .arrow-up {
                display: none;
                font-size: 10px;
                line-height: 50px;
            }

            .arrow-down {
                font-size: 10px;
                line-height: 50px;
            }
        }

        &.open {
            .arrow-up {
                display: inline;
            }

            .arrow-down {
                display: none;
            }
        }
    }

    .cta-menu-dropdown,
    .cta-menu2-dropdown {
        border-radius: 5px;
        position: relative;
        z-index: 10;
        height: 0;
        overflow: hidden;
        padding: 0 15px;
        transition: height 0.2s linear;
        text-align: center;

        &.open {
            padding: 0 15px 15px 15px;
            transition: height 0.2s linear;
        }

        li,
        a {
            display: block;
            width: 100%;
            color: white;
            font-weight: normal;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 32px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cta-menu-dropdown {
        background-color: #077AC0;
    }

    .cta-menu2-dropdown {
        background-color: white;
    }
}
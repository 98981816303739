section.tabs-container {
	border-radius: 5px;
	&.bordered-component {
		@include theme(border, cb-border-default, 1px solid);


		// accordion-button
		.accordion:last-child .accordion-button:not(.active) {
			border-bottom: 0;
		}
	}

	> div.tabs-buttons-container {
		display: flex;
		justify-content: space-around;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;

		> div.tab-button {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-grow: 1;
			text-align: center;
			min-height: 50px;
			max-height: 100px;
			cursor: pointer;
			box-sizing: border-box;
			background: none;
			font-size: 16px;
			font-weight: bold;
			@include theme(color, cb-primary-main);
			@include theme(border-bottom, cb-border-default, 1px solid);
			@include theme(border-right, cb-border-default, 1px solid);
			background-color: #FFFFFF;

			&:first-child {
				border-top-left-radius: 5px;
			}

			&:last-child {
				margin-right: 0;
				border-right: 0;
				border-top-right-radius: 5px;
			}

			img {
				opacity: 50%;
				transition: all 0.3s;
				max-width: 200px;
				height: auto;
			}

			&.active {
				@include theme(border-bottom, cb-primary-main, 6px solid);
				@include theme(color, cb-primary-main);
				@include theme(background-color, bg-secondary);

				img {
					opacity: 100%;
					transition: all 0.3s;
				}
			}

			&:hover {
				@include theme(color, primary-dark);
				transition: 0.3s;
			}
		}
	}
	> div.tab-container {
		&.padded-tab {
			padding: 20px;
		}

		> div.tab-content {
			display: none;

			
			&.active {
				display: block;
			}
		}
	}
	&.edit {
		> div.tab-container {
			> div.tab-content {
				display: block;
			}
		}
	}
}

@media (max-width: 1279px) {
	section.tabs-container > div.tab-container.padded-tab {
		padding: 10px;
	}
}

@media (max-width: 576px) {
	section.tabs-container {
		> div.tabs-buttons-container {
			> div.tab-button {
				img {
					width: 50%;
					height: auto;
				}
			}
		}
	}
}

div.searchCategory {
	div.list-group {
		div.result {
			display: flex;
			flex-direction: column;
			&:first-child {
				@include theme(border-top, border, solid 0px);
			}
			.banner {
				position: relative;
				overflow: hidden;
				height: 25vh;
				a {
					img {
						width: 100%;
					}
					h2 {
						position: absolute;
						bottom: 2rem;
						width: 100%;
						background-color: rgba($color: white, $alpha: 0.75);
						padding: 0.5rem;
						font-size: 2rem;
						@include theme(color, fg-secondary);
					}
					&:hover {
						transition: 0.5s;
						h2 {
							@include theme(color, link-idle);
						}
					}
				}
			}
			h4 {
				//date
				font-size: 0.9rem;
				@include theme(color, fg-quaternary);
			}
			p {
				//excert
				font-size: 1.1rem;
				@include theme(color, fg-quaternary);
			}
			p.item-date {
				font-size: 20px;
				margin-bottom: 0;

			}
			a.btn {
				margin: 1rem 0;
				align-self: flex-end;
			}
		}
	}
}

.link-list-wrapper {
	ul.link-list {
		margin-bottom: 30px;
		li.link-list-item {
			list-style-type: none;
			border-bottom: 1px solid;
			margin: 0 0;
			@include theme(border-color, border);

			> a {
				display: block;
				width: 100%;
				font-size: 17px;
				font-weight: 300;
				padding: 20px 5px;
				@include theme(color, link-idle);
				transition: all 0.3s;

				&:hover {
					transition: all 0.3s;
					@include theme(color, link-hover);
					@include theme(background-color, bg-quaternary);
				}

				span.link-list-subtitle {
					display: block;
					font-size: 14px;
					font-weight: 300;
					@include theme(color, text-secondary);
				}
			}
		}
	}
}

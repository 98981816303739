// $color:   "blue", "grey";
// $color-var:   "icon", "iconBorder";
// $animation-name:  "waveBlack", "waveBlue", "waveGray";
// $animation-hex-color:  "#363636", "#084081", "#CCCCCC";
// $lenght-color: length($color);
// $size-choices: 5;

div.icon-container{
    margin: .5rem 0;
    & ,a{
        display: flex;
    }
    &.text-vertical, &.text-vertical a{
        
        flex-direction: column;
        align-items: center;
        div.theIcon{
            margin-bottom: 1rem;
        }
    }
    &.text-horizontal, &.text-horizontal a{
        flex-direction: row;
    }
    &.circle{
        div.theIcon{
            border-radius: 100%;
            svg.icon{
                width: 50%;
            }
            &.disk{
                //Couleur de le contour
                // @for $i from 1 through $lenght-color {
                //     &.icon-bg-#{nth($color, $i)}{ 
                //         @include theme(background-color, nth($color-var, $i));
                //     }
                // }
            }
            &.border{
                //Couleur de le contour
                // @for $i from 1 through $lenght-color {
                //     &.icon-bg-#{nth($color, $i)}{ 
                //         @include theme(border, nth($color-var, $i), solid 1px);
                //     }
                // }
            }
            &.diskBorder{
                //Couleur de l'icon
                // @for $y from 1 through $lenght-color {
                //     &.icon-#{nth($color, $y)}{
                //         //Couleur de le contour
                //         @for $i from 1 through $lenght-color {
                //             &.icon-bg-#{nth($color, $i)}{
                //                 @include theme(border, nth($color-var, $i), solid 2px);
                //                 svg.icon{
                //                     width: 100%;
                //                     border-radius: 100%;
                //                     padding: 1vw;
                //                     overflow: visible;
                //                     @include theme(background-color, nth($color-var, $i));
                //                     @include theme(border, nth($color-var, $y), solid 1px);
                //                     use{
                //                         width: 95%;
                //                         @include theme(fill, nth($color-var, $y));
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // }
            }
        }
    }
    &.animated{
        div.theIcon{
            position: relative;
            &::after{
                box-shadow: 0 0 0 2px rgba(255, 255, 255, 0%), 0 0 0 4px ;
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                content: '';
                top: 0;
                left: 0;
                padding: 0;
                z-index: 0;
                pointer-events: none;
                opacity: 0;
                box-sizing: content-box;
            }
            // @for $i from 1 through $lenght-color {
            //     &.icon-bg-#{nth($color, $i)}{
            //         &::after{
            //             box-shadow: 0 0 0 2px rgba(255, 255, 255, 0%), 0 0 0 4px #{nth($animation-hex-color, $i)};
            //         }
            //         &:hover::after{
            //             animation-duration: 1s;
            //             animation-name: #{nth($animation-name, $i)};
            //             animation-timing-function: ease;
            //         }
            //     }
            // }
        }
    }
    //Couleur de l'icon
    // @for $i from 1 through $lenght-color {
    //     .icon-#{nth($color, $i)}{
    //         svg.icon{
    //             use{
    //                 @include theme(fill, nth($color-var, $i));
    //             }
    //         }
    //     }
    // }
    
    div.theIcon{
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        height: 5rem;
        
        // @for $i from 1 through $size-choices {
        //     &.size-#{$i}{ 
        //         width: $i + rem;
        //         height: $i + rem;
        //     }
        // }
    }
    div.rich_text{
        margin: 0 1rem;
        //Couleur du texte
        // @for $i from 1 through $lenght-color {
        //     &.text-#{nth($color, $i)}{ 
        //         p, li, ol{
        //             @include theme(color, nth($color-var, $i));
        //         }
        //     }
        // }
    }
}
// @for $i from 1 through $lenght-color {
//     @keyframes #{nth($animation-name, $i)} { 
//         $intensity: 10;
//         from {
//             box-shadow: 0 0 0 2px rgba(255, 255, 255, 0%), 0 0 0 4px #{nth($animation-hex-color, $i)};
//             top: 0;
//             left: 0;
//             padding: 0;
//             opacity: 1;
//         }
    
//         to {
//             box-shadow: 0 0 0 2px rgba(255, 255, 255, 0%), 0 0 #{$intensity}px 4px #{nth($animation-hex-color, $i)};
//             top: -#{$intensity}px;
//             left: -#{$intensity}px;
//             padding: #{$intensity}px;
//             opacity: 0;
//         }
//     }
// }

.rich_text,
.section-description {
	//Strong
	strong {
		font-weight: bold;
	}

	// Normal link blue
	a.normal-link {
		letter-spacing: -0.333333px;
		font-size: 1rem;
		@include theme(color, primary-main);
		text-decoration: underline;
		&:hover {
			@include theme(color, primary-dark);
		}
	}
	ul {
		li {
			display: table;
			vertical-align: top;
			width: 100%;
			margin-bottom: 10px;
			line-height: 1.4em;
			align-items: center;

			&::before{
				display: table-cell;
				vertical-align: top;
				width: 18px;
				padding-right: 10px;
				content: url("../asset/resource/svg/check1.svg");
			}


			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ul.no-margin {
		li {
			margin-left: 0;
		}
		
	}
	// Text primary-main
	span.primary-main {
		@include theme(color, primary-main);
		a {
			@include theme(color, primary-main);
			text-decoration: underline;
			&:hover {
				@include theme(color, secondary-main);
				text-decoration: none;
			}
		}
	}
	// Text common-white
	span.common-white {
		@include theme(color, common-white);
		a {
			@include theme(color, common-white);
			text-decoration: underline;
			&:hover {
				@include theme(color, common-white);
				text-decoration: none;
			}
		}
	}
}
.rich-title {
	//Title subscription
	.subs {
		font-size: 34px;
		line-height: 38px;
		margin-top: 30px;
	}
	//Color primary-main blue
	.span-blue {
		@include theme(color, primary-main);
	}

	//Color secondary-main orange
	.span-orange {
		@include theme(color, secondary-main);
	}

	//Color bluecross blue
	.span-blue-bc {
		@include theme(color, brand);
	}

	//H1 low line-height
	h1.h1-line-height {
		line-height: 50px;
	}

	//Color common-white
	span.span-white {
		@include theme(color, common-white);
	}
}

div.search-bar {
	padding: 1rem 0;
	form {
		height: 2.8rem;
		input {
			height: 100%;
			transform: translateY(-1px);
			@include theme(border, border, 1px solid);
			width: 250px;
			border-radius: 3px;
			font-size: 1.1rem;
		}
		button {
			border: none;
			border-radius: 3px;
			height: 100%;
			font-size: 1.1rem;
			@include theme(color, fg-terciary);
			@include theme(background-color, btn1);
			padding: 0 1rem;
			transition: 0.5s;
			&:hover {
				@include theme(background-color, btn2);
			}
		}
	}
}

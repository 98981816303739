div.splash-container {
	background-size: cover;
	background-repeat: no-repeat;
	&.content-area {
		padding-bottom: 0;
	}
	div.splash {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		section {
			margin: 3rem 52.5% 3rem 1rem;
			@include theme(background-color, bg-primary);

			.content-area {
				.container {
					.content-section {
						.column-container {
							.wrapper {
								.flex-container.px-2 {
									.flex-item:first-child {
										padding-left: 0;
									}
									.flex-item:last-child {
										padding-right: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 768px) {
	div.splash-container {
		padding-top: 0;
		div.container {
			padding: 0;

			.content-section {
				div.column-container {
					padding: 0 0 0 0 !important;

					.wrapper {
						div.flex-container {
							> div.flex-item {
								margin-bottom: 0;
								padding: 0;
							}
						}

						div.flex-container.px-0 {
							> div.flex-item {
								margin-bottom: 0;
								padding: 0;
							}
						}
					}
					div.splash {
						padding: 30px 30px 0;

						section {
							padding: 0 20px;
							margin: 0 0 0 0;

							.text-block {
								padding: 0 10px;
								margin-left: 0;
								margin-right: 0;
							}

							.cta-container {
								padding: 0 10px;
								margin-left: 0;
								margin-right: 0;
							}

							.content-area {
								.container {
									.content-section {
										.column-container {
											.wrapper {
												.flex-container.px-2 {
													.flex-item:first-child {
														padding-left: 0.5rem;
													}
													.flex-item:last-child {
														padding-right: 0.5rem;
													}
												}
											}
										}
									}
								}
							}
						}
						footer {
							display: none;
						}

						.half-arrow {
							width: 15vw;
							height: 7.5vw;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 599px) {
    div.splash-container {

		div.container {

			.content-section {
				div.column-container {
		
					div.splash {
                        padding: 0;
						
                        section {
                            
							.text-block {
								padding: 0;
							} 
                        }
                    }
                }
            }
        }
                    
    }
}
.destination-page {
	display: flex;
	flex-direction: column;

	> img {
		margin-bottom: 1.5rem;
	}
	div.title-flag-section {
		display: flex;
		align-items: center;
		h2 {
			margin: 0 1rem;
		}
	}
	div.comp-canada-ctrl {
		display: flex;
		justify-content: flex-end;
		@include theme(border-bottom, primary-main, 2px solid);
		a {
			display: flex;
			align-items: center;
			margin: 0.1rem;
			border-radius: 3px;
			@include theme(background-color, primary-main);
			&:hover {
				@include theme(background-color, primary-dark);
			}
			&.hide {
				.open {
					display: none;
				}
			}
			&.show {
				.close {
					display: none;
				}
			}
			svg,
			img {
				width: 18px;
				margin: 0.3rem;
			}
			svg {
				use {
					@include theme(fill, primary-contrast);
				}
			}
		}
	}
	table.desti-datas {
		width: 100%;
		span {
			opacity: 0;
			transition: 1s;
		}
		&.show-ref {
			span {
				opacity: 1;
			}
		}
		thead {
			tr {
				th {
					text-align: start;
				}
				td {
					img {
						width: 18px;
					}
				}
			}
		}
		tr {
			height: 3rem;
			td,
			th {
				vertical-align: middle;
			}
		}
		th,
		td {
			@include theme(color, common-black);
		}
	}
	> a {
		align-self: center;
	}
}

footer {
	.footer-top-bg {
		padding: 30px 0;
		@include theme(background-color, footer-bg);
		@include theme(color, footer-fg-primary);
	}

	.footer-bottom-bg {
		padding: 30px 0;
		background-color: white;
		@include theme(color, fg-primary);
	}

	.container {
		.column-container {
			padding: 0 15px;
		}

		.links-columns {
			div.flex-container {
				display: flex;
				flex-flow: row wrap;
				justify-content: left;
				align-items: flex-start;
				justify-content: space-between;

				> div {
					display: block;
					margin: 0;
					padding: 10px 0;
					width: 25%;

					a {
						display: block;
						@include theme(color, footer-fg-primary);
						line-height: 1.3;
						font-size: 13px;
						font-weight: bold;
						margin: 5px 0 10px;

						&:hover {
							text-decoration: underline;
						}
					}

					ul {
						display: flex;
						flex-flow: column wrap;
						justify-content: left;
						align-items: flex-start;
						li {
							list-style: none;
							margin: 5px 0 10px;
							padding: 0px;
							a {
								margin: 0;
								font-weight: normal;
							}
						}
					}
					.social-media-links {
						margin-top: 20px;

						.social-media-links-column-title {
							@include theme(color, footer-fg-primary);
							line-height: 1.3;
							font-size: 13px;
							font-weight: bold;
						}

						> ul {
							display: block;
							padding-top: 10px;
							li {
								display: inline-block;
								margin-right: 10px;

								a:hover {
									img {
										filter: brightness(115%);
									}
								}

								img {
									width: 32px;
									height: 32px;
								}

								&:last-child {
									margin-right: 0;
								}
							}
						}
					}
				}
			}
		}

		.footer-privacy-links {
			text-align: center;

			ul {
				li {
					display: inline-block;
					margin: 0 0.5em;
					list-style-type: none;
					a {
						font-size: 13px;
						font-weight: bold;
						@include theme(color, fg-primary);
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		.footer-address {
			text-align: center;
			font-size: 13px;
		}

		.footer-copyright {
			display: flex;
			margin-top: 10px;
			font-size: 13px;
			text-align: center;
			align-items: center;
			justify-content: center;

			span {
				display: inline-block;
			}

			.copyright-image {
				display: inline-block;
				max-height: 35px;
				margin-left: 20px;
			}
		}

		.footer-richtext{
			padding: 1rem 0;

			&:empty {
				display: none;
			}
		}

		.footer-logo {
			text-align: center;
			img {
				margin-top: 20px;
				max-height: 40px;
			}
		}
	}
}

@media (min-width: 577px) and (max-width: 768px) {
	footer {
		.container {
			.links-columns {
				div.flex-container {
					padding: 0 10%;
					> div {
						min-width: 50%;
						justify-content: center;
					}
				}
			}
			.footer-copyright {
				display: flex;
				clear: both;
				text-align: center;
				align-items: center;
				justify-content: center;

				span {
					display: inline-block;
					line-height: 35px;
				}

				.copyright-image {
					display: inline-block;
					margin-left: 20px;
				}
			}
		}
	}
}

@media (max-width: 576px) {
	footer {
		.container {
			.links-columns {
				div.flex-container {
					padding: 0 15%;
					> div {
						display: block;
						clear: both;
						justify-content: center;
						width: 100%;
					}
				}
			}
			.footer-copyright {
				display: block;
				clear: both;
				width: 100%;
				text-align: center;
				span {
					display: block;
					text-align: center;
					clear: both;
				}
				.copyright-image {
					display: block;
					clear: both;
					text-align: center;
					margin: 10px auto;
				}
			}
		}
	}
}

div.searchResults {
	margin-bottom: 2rem;
	h3 {
		@include theme(color, fg-quaternary);
		font-size: 0.8rem;
		margin-bottom: 10px;
		strong {
			font-weight: bold;
		}
	}
	div.list-group {
		div.result {
			@include theme(border-top, border, 1px solid);
			padding: 1rem 0;
			a {
				h4 {
					@include theme(color, link-idle);
					font-size: 1.5rem;
					&:hover {
						@include theme(color, link-hover);
					}
				}
			}
			div.excerpt {
				padding-left: 1rem;
				@include theme(color, fg-quaternary);
				strong {
					font-weight: bold;
				}
				* {
					@include theme(color, fg-quaternary);
				}
			}
		}
	}
	nav.search-nav {
		display: flex;
		justify-content: center;
		align-items: center;
		@include theme(border-top, brand, solid 3px);
		padding: 1rem 0;
		a {
			svg {
				width: 1.7rem;
				use {
					@include theme(fill, btn1);
				}
			}
			&:hover svg use {
				transition: 0.3s;
				@include theme(fill, btn2);
			}
		}
		ul {
			display: flex;
			list-style: none;
			li {
				width: 68px;
				display: flex;
				justify-content: center;
				align-items: center;
				@include theme(border-left, border, 1px solid);
				@include theme(border-right, border, 1px solid);
				@include theme(border-bottom, bg-primary, 1px solid);
				@include theme(color, pageNavIndex-fg);
				&:hover {
					@include theme(border-bottom, pageNavIndexBottomBorder, 1px solid);
				}
				&:last-child {
					border-right: none;
				}
				&:first-child {
					border-left: none;
				}
				a {
					padding: 0.3rem 0;
					width: 100%;
					text-align: center;
					@include theme(color, link-idle);
					transition: 0.3s;
					&:hover {
						@include theme(color, link-hover);
					}
				}
			}
		}
	}
	footer {
		background: none;
		div.results-pagination {
			font-style: italic;
			font-size: 0.8rem;
			text-align: center;
			strong {
				font-weight: bold;
			}
		}
	}
}
